/* RESPONSIBLE TEAM: team-workflows */
import {
  TRIGGERABLE_BOT_TYPE,
  CONVERSATION_STATUS_CHANGED_TARGET,
  CONVERSATION_STATUS_CHANGED_TRIGGER,
  DURING_CONVERSATION,
  WORKFLOWS_SETUP_TRIGGER_DURING_CONVERSATION,
  WORKFLOWS_SETUP_TRIGGER_INTERNAL_PROCESS,
} from 'embercom/lib/operator/custom-bots/constants';
import {
  matchBehaviors,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import ConversationStatusChangedEditorConfig from 'embercom/objects/visual-builder/configuration/editor/conversation-status-changed';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

import CustomBotConfig, { type ColumnConfigs, type TriggerPredicates } from '../custom-bot';
import type { BotConfigTarget } from '../configuration';

export default class ConversationStatusChangedCustomBotConfig extends CustomBotConfig {
  get target(): BotConfigTarget {
    return CONVERSATION_STATUS_CHANGED_TARGET;
  }

  get botType() {
    return TRIGGERABLE_BOT_TYPE;
  }

  get objectType() {
    return objectTypes.triggerableCustomBot;
  }

  get trigger() {
    return CONVERSATION_STATUS_CHANGED_TRIGGER;
  }

  get matchBehavior() {
    return matchBehaviors.transient;
  }

  get titleTranslationKey() {
    return 'operator.custom-bot.trigger.conversation-status-changed.title';
  }

  get descriptionTranslationKey() {
    return 'operator.custom-bot.trigger.conversation-status-changed.description';
  }

  get icon(): InterfaceIconName {
    return 'active';
  }

  get editorConfig() {
    return new this.EditorConfigClass({
      targetChannels: this.customBot?.targetChannels,
      areStepPaywallsActive: this.areStepPaywallsActive,
      workflow: this.workflow,
    });
  }

  get EditorConfigClass() {
    return ConversationStatusChangedEditorConfig;
  }

  get triggerPredicates(): TriggerPredicates {
    return {
      type: 'or',
      options: [
        {
          icon: 'close-conversation',
          iconSet: 'standard',
          value: 'state_closed',
          translationKey: 'operator.custom-bot.trigger.conversation-status-changed.states.closed',
          predicate: {
            attribute: 'conversation.conversation_state',
            type: 'string',
            comparison: 'eq',
            value: 'state_closed',
          },
        },
        {
          icon: 'snooze',
          iconSet: 'standard',
          value: 'state_snoozed',
          translationKey: 'operator.custom-bot.trigger.conversation-status-changed.states.snoozed',
          predicate: {
            attribute: 'conversation.conversation_state',
            type: 'string',
            comparison: 'eq',
            value: 'state_snoozed',
          },
        },
        {
          icon: 'reopen-conversation',
          iconSet: 'standard',
          value: 'state_open',
          translationKey: 'operator.custom-bot.trigger.conversation-status-changed.states.open',
          predicate: {
            attribute: 'conversation.conversation_state',
            type: 'string',
            comparison: 'eq',
            value: 'state_open',
          },
        },
      ],
    };
  }

  get columnKeys(): (keyof ColumnConfigs)[] {
    let baseColumns: (keyof ColumnConfigs)[] = super.columnKeys;

    return [...baseColumns.slice(0, 2), 'channels', ...baseColumns.slice(2)];
  }

  get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  get triggerContextType() {
    return DURING_CONVERSATION;
  }

  get workflowsSetupTriggerType(): string[] {
    return [WORKFLOWS_SETUP_TRIGGER_DURING_CONVERSATION, WORKFLOWS_SETUP_TRIGGER_INTERNAL_PROCESS];
  }
}
