/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-workflows */
/* eslint-disable no-restricted-imports */
import type MutableArray from '@ember/array/mutable';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';
import { getAttributeService } from 'embercom/lib/container-lookup';
import EditorConfig, {
  type AttributeGroupList,
} from 'embercom/objects/visual-builder/configuration/editor';
import type Workflow from 'embercom/models/operator/visual-builder/workflow';

export default class TargetContextEditorConfig extends EditorConfig {
  public readonly isBackgroundOnly = true;
  public readonly canBeTicketTrigger = true;
  public readonly targetChannels: MutableArray<string>;
  public readonly targetContexts: TargetContext[];
  constructor({
    targetChannels,
    areStepPaywallsActive,
    targetContexts,
    workflow,
  }: {
    targetChannels: MutableArray<string>;
    areStepPaywallsActive: boolean;
    targetContexts: TargetContext[] | null;
    workflow?: Workflow;
  }) {
    super({ areStepPaywallsActive, workflow });
    this.targetContexts = targetContexts || [TargetContext.CustomerTicket];
    this.targetChannels = targetChannels;
    this.attributeService = getAttributeService();
  }

  get supportsUserTargeting(): boolean {
    return !this.targetContexts.includes(TargetContext.TrackerTicket);
  }

  get showAudiencePreview(): boolean {
    return false;
  }

  get showGoalsPanel(): boolean {
    return false;
  }

  get workflowMatchingTargetingAttributes(): AttributeGroupList {
    let groups = [
      ...this.attributeService.attributesToGroupList(
        this.attributeService.visualBotBuilderConditionalAttributes,
      ),
      ...this.attributeService.ticketAttributesByType,
    ];

    // Filter out user attributes if they're not allowed
    if (!this.supportsUserTargeting) {
      groups = this.excludeUserAndCompanyAttributes(groups);
    }

    // Filter out message and conversation attributes if they're not allowed
    if (!this.includeConversationAttributes) {
      groups = this.excludeMessageAndConversationAttributes(groups);
    }

    // Filter out attributes that are not applicable to the target context
    groups = this.filterInapplicableAttributesForTarget(groups);

    if (this.ticketTargetContexts.length === 0) {
      // Filter ticket attributes if no ticket target contexts are selected
      groups = this.excludeTicketAttributes(groups);
    } else {
      // Filter ticket category attribute from trigger settings
      groups = this.filterTicketCategoryAttribute(groups);
    }

    return groups;
  }

  get supportsConversationChannelTargeting(): boolean {
    let unsupportedContexts = [TargetContext.BackOfficeTicket, TargetContext.TrackerTicket];
    return this.targetContexts.every((context) => !unsupportedContexts.includes(context));
  }

  get targetToEntityMapping(): string[] {
    let mapping: Record<TargetContext, string> = {
      [TargetContext.Conversation]: 'conversation',
      [TargetContext.CustomerTicket]: 'customer_ticket',
      [TargetContext.BackOfficeTicket]: 'back_office_ticket',
      [TargetContext.TrackerTicket]: 'tracker_ticket',
    };

    return this.targetContexts.map((context) => mapping[context]);
  }

  get runtimeMatchingTargetingAttributes(): AttributeGroupList {
    let groups = [...super.runtimeMatchingTargetingAttributes];

    // Filter out user attributes if they're not allowed
    if (!this.supportsUserTargeting) {
      groups = this.excludeUserAndCompanyAttributes(groups);
    }

    // Filter out message and conversation attributes if they're not allowed
    if (!this.includeConversationAttributes) {
      groups = this.excludeMessageAndConversationAttributes(groups);
    }

    // Filter out attributes that are not applicable to the target context
    groups = this.filterInapplicableAttributesForTarget(groups);

    switch (this.ticketTargetContexts.length) {
      case 0:
        // Filter ticket attributes if no ticket target contexts are selected
        groups = this.excludeTicketAttributes(groups);
        break;
      case 1:
        // Filter ticket category attribute from branch if a single ticket target context is selected
        groups = this.filterTicketCategoryAttribute(groups);
    }

    return groups;
  }

  private excludeTicketAttributes(groups: AttributeGroupList): AttributeGroupList {
    return this.excludeGroupsWithHeadings(groups, ['Ticket data']);
  }

  get ticketTargetContexts() {
    return this.targetContexts.filter((context) =>
      [
        TargetContext.CustomerTicket,
        TargetContext.BackOfficeTicket,
        TargetContext.TrackerTicket,
      ].includes(context),
    );
  }

  private filterInapplicableAttributesForTarget(
    attributes: AttributeGroupList,
  ): AttributeGroupList {
    return attributes.map((originalGroup) => {
      // Clone the group so we don't mutate the original object - this can cause issues when switching between contexts
      let group = Object.assign({}, originalGroup);
      group.attributes = group.attributes.filter((attribute) => {
        if (attribute.entityTypes) {
          return this.targetToEntityMapping.every((context) =>
            attribute.entityTypes.includes(context),
          );
        }
        return true;
      });
      return group;
    });
  }

  private excludeUserAndCompanyAttributes(groups: AttributeGroupList): AttributeGroupList {
    return this.excludeGroupsWithHeadings(groups, ['Person data', 'Company data']);
  }

  private excludeMessageAndConversationAttributes(groups: AttributeGroupList): AttributeGroupList {
    return this.excludeGroupsWithHeadings(groups, ['Conversation data', 'Message data']);
  }

  private excludeGroupsWithHeadings(
    groups: AttributeGroupList,
    headings: string[],
  ): AttributeGroupList {
    return groups.filter((group) => !group.heading || !headings.includes(group.heading));
  }

  private get includeConversationAttributes() {
    let supportedContexts = [TargetContext.CustomerTicket, TargetContext.Conversation];
    return this.targetContexts.every((context) => supportedContexts.includes(context));
  }

  private filterTicketCategoryAttribute(groups: AttributeGroupList): AttributeGroupList {
    return groups.map((orignalGroup) => {
      // Clone the group so we don't mutate the original
      let group = Object.assign({}, orignalGroup);
      group.attributes = group.attributes.filter((attribute) => {
        return attribute.cda_id !== 'ticket_category';
      });
      return group;
    });
  }
  get targetingSectionTitle(): string {
    return this.intl.t('operator.workflows.visual-builder.workflow-trigger-node.filters');
  }

  get targetingAttributePickerLabel(): string {
    return this.intl.t('operator.workflows.visual-builder.workflow-trigger-node.add-filters');
  }
}
