/* RESPONSIBLE TEAM: team-workflows */
import {
  TRIGGERABLE_BOT_TYPE,
  DURING_CONVERSATION,
  CONVERSATION_ASSIGNMENT_CHANGED_TARGET,
  CONVERSATION_ASSIGNMENT_CHANGED_TRIGGER,
  WORKFLOWS_SETUP_TRIGGER_DURING_CONVERSATION,
  WORKFLOWS_SETUP_TRIGGER_INTERNAL_PROCESS,
} from 'embercom/lib/operator/custom-bots/constants';
import {
  matchBehaviors,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';

import CustomBotConfig, { type ColumnConfigs } from '../custom-bot';
import ConversationAssignmentChangedEditorConfig from 'embercom/objects/visual-builder/configuration/editor/conversation-assignment-changed';
import type { BotConfigTarget } from '../configuration';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export default class ConversationAssignmentChangedCustomBotConfig extends CustomBotConfig {
  get target(): BotConfigTarget {
    return CONVERSATION_ASSIGNMENT_CHANGED_TARGET;
  }

  get botType() {
    return TRIGGERABLE_BOT_TYPE;
  }

  get objectType() {
    return objectTypes.triggerableCustomBot;
  }

  get trigger() {
    return CONVERSATION_ASSIGNMENT_CHANGED_TRIGGER;
  }

  get matchBehavior() {
    return matchBehaviors.transient;
  }

  get titleTranslationKey() {
    return 'operator.custom-bot.trigger.conversation-assignment-changed.title';
  }

  get descriptionTranslationKey() {
    return 'operator.custom-bot.trigger.conversation-assignment-changed.description';
  }

  get triggerPredicatesDescriptionTranslationKey() {
    return 'operator.custom-bot.trigger.conversation-assignment-changed.trigger-predicates-description';
  }

  get icon(): InterfaceIconName {
    return 'assignment';
  }

  get editorConfig() {
    return new this.EditorConfigClass({
      targetChannels: this.customBot?.targetChannels,
      areStepPaywallsActive: this.areStepPaywallsActive,
      workflow: this.workflow,
    });
  }

  get EditorConfigClass() {
    return ConversationAssignmentChangedEditorConfig;
  }

  get columnKeys(): (keyof ColumnConfigs)[] {
    let baseColumns: (keyof ColumnConfigs)[] = super.columnKeys;

    return [...baseColumns.slice(0, 2), 'channels', ...baseColumns.slice(2)];
  }

  get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  get triggerContextType() {
    return DURING_CONVERSATION;
  }

  get workflowsSetupTriggerType(): string[] {
    return [WORKFLOWS_SETUP_TRIGGER_DURING_CONVERSATION, WORKFLOWS_SETUP_TRIGGER_INTERNAL_PROCESS];
  }
}
