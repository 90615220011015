/* RESPONSIBLE TEAM: team-help-desk-experience */
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import {
  BaseConfig,
  EMOJI_TYPEAHEAD,
  type InitialSelection,
  INPUT_RULE_CODE_BLOCK,
  INPUT_RULE_EMOJI,
  INPUT_RULE_INLINE_CODE,
  INPUT_RULE_MARKDOWN_ANCHOR,
  INPUT_RULE_ORDERED_LIST,
  INPUT_RULE_UNORDERED_LIST,
  type TextDirection,
  type TypeaheadConfig,
  type UploadConfig,
} from '@intercom/embercom-prosemirror-composer';
import { type CustomInputRuleConfig } from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';
import type ComposerConfig from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';
import { SkinToneModifiers } from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';
import { triggerCharacter } from '@intercom/embercom-prosemirror-composer/lib/composer/typeahead/trigger-character';
import { type AttributeInfoResolver, type CanvasNetworkClient } from '@intercom/interblocks.ts';
import { LOADING_CANVAS } from 'embercom/objects/inbox/canvas-view-loader';
import type AttributesApi from 'embercom/services/attributes-api';
import { EmbercomCanvasNetworkClient } from 'embercom/objects/composer/embercom-canvas-network-client';
import type Macro from './macro';
import type Session from 'embercom/services/session';
import { setOwner } from '@ember/application';

export const MENTION_TYPEAHEAD = {
  name: 'mention',
  matcher: triggerCharacter('@', { allowSpaces: true }),
  component: 'inbox2/composer/mention-typeahead',
};

function macroTypeahead(
  shortcutKey: string,
  useMacro: (macro: Macro) => void,
  conversationId?: number,
) {
  return {
    name: `macro_${shortcutKey}`,
    matcher: triggerCharacter(shortcutKey, { allowSpaces: true }),
    component: 'inbox2/composer/macro-typeahead',
    attrs: { useMacro, conversationId },
  };
}

export interface MacrosTypeaheadConfig {
  useMacro: (macro: Macro) => void;
  conversationId?: number;
}
export class Config extends BaseConfig {
  @service declare attributesApi: AttributesApi;

  @service declare session: Session;

  @tracked typeaheads = [EMOJI_TYPEAHEAD];

  canvasNetworkClient?: CanvasNetworkClient;
  canvasLoadingImage = LOADING_CANVAS;

  renderOverlaysInPlace = false;
  autoFocus = false;

  tools = [
    { name: 'text-formatter' },
    { name: 'anchor-editor' },
    { name: 'fallback-editor' },
    { name: 'image-editor', options: { supportAltAttributeEditing: false } },
    {
      name: 'html/delete-button',
      options: {
        // the closest wrapper that will bound the movement of the button tooltip
        clippingParentClass:
          // eslint-disable-next-line @intercom/intercom/no-bare-strings
          '.inbox2__expandable-composer-wrapper',
      },
    },
  ];

  formatters?: ComposerConfig['formatters'];

  textDirection = 'auto' as TextDirection;
  initialSelectionLocation = 'end' as InitialSelection;

  allowedBlocks = [
    'paragraph',
    'image',
    'orderedList',
    'unorderedList',
    'heading',
    'subheading',
    'codeBlock',
    'mention',
    'messengerCard',
    'html',
  ];

  allowedInline = ['bold', 'italic', 'anchor', 'code'];

  inputRules = [
    INPUT_RULE_CODE_BLOCK,
    INPUT_RULE_INLINE_CODE,
    INPUT_RULE_ORDERED_LIST,
    INPUT_RULE_UNORDERED_LIST,
    INPUT_RULE_EMOJI,
    INPUT_RULE_MARKDOWN_ANCHOR,
  ];

  allowTextAlignment = false;

  upload: UploadConfig;
  templating?: ComposerConfig['templating'];

  constructor(
    owner: unknown,
    workspaceId: string,
    uploadConfig: UploadConfig,
    customInputRule?: CustomInputRuleConfig,
    resolver?: AttributeInfoResolver,
    allowMentions = true,
    skinToneModifier: SkinToneModifiers = SkinToneModifiers.NoSkinToneModifier,
    macrosTypeaheadConfig?: MacrosTypeaheadConfig,
  ) {
    super();
    setOwner(this, owner);

    this.initializeMentions(allowMentions);
    this.upload = uploadConfig;

    if (this.session.workspace.isFeatureEnabled('inbox-composer-inline-image')) {
      this.allowedBlocks.push('inlineImage');
      this.allowedInline.push('inlineImage');
    }

    if (macrosTypeaheadConfig) {
      this.insertTypeahead(
        macroTypeahead('#', macrosTypeaheadConfig.useMacro, macrosTypeaheadConfig.conversationId),
      );
      this.insertTypeahead(
        macroTypeahead('\\', macrosTypeaheadConfig.useMacro, macrosTypeaheadConfig.conversationId),
      );
    } else if (customInputRule) {
      this.configureInputRules(customInputRule);
    }
    this.canvasNetworkClient = new EmbercomCanvasNetworkClient(workspaceId);
    if (resolver) {
      this.templating = { picker: 'common/attribute-picker', resolver };
    }

    this.skinToneModifier = skinToneModifier;
  }

  insertTypeahead(typeahead: TypeaheadConfig) {
    this.typeaheads = [...this.typeaheads, typeahead];
  }

  removeTypeahead(typeahead: TypeaheadConfig) {
    this.typeaheads = this.typeaheads.without(typeahead);
  }

  removeAllowedBlock(block: string) {
    this.allowedBlocks = this.allowedBlocks.without(block);
  }

  enableTables() {
    if (this.session.workspace.isFeatureEnabled('inbox-composer-tables')) {
      this.allowedBlocks.push('table');
      this.tables = {
        resizeColumns: true,
        responsive: false,
        containers: false,
      };
      this.tools.push({ name: 'table-editor' });
    }
  }

  private configureInputRules(rule: CustomInputRuleConfig) {
    this.inputRules.push(rule);
  }

  private initializeMentions(allowMentions: boolean) {
    if (allowMentions) {
      this.insertTypeahead(MENTION_TYPEAHEAD);
    } else {
      this.removeAllowedBlock('mention');
    }
  }
}
