/* RESPONSIBLE TEAM: team-workflows */
/* eslint-disable no-restricted-imports */
import {
  INBOUND_BOT_TYPE,
  NEW_CONVERSATION_TARGET,
  AT_CONVERSATION_START,
  WORKFLOWS_SETUP_TRIGGER_START_CONVERSATION,
  WORKFLOWS_SETUP_TRIGGER_CUSTOMER_FACING,
} from 'embercom/lib/operator/custom-bots/constants';
import {
  matchBehaviors,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import InboundBotEditorConfig from 'embercom/objects/visual-builder/configuration/editor/inbound-bot';
import type { BotConfigTarget } from '../configuration';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

import CustomBotConfig from '../custom-bot';
import { getApp } from 'embercom/lib/container-lookup';
import StandaloneInboundBotEditorConfig from 'embercom/objects/visual-builder/configuration/editor/standalone-inbound-bot';

export default class InboundCustomBotConfig extends CustomBotConfig {
  get target(): BotConfigTarget {
    return NEW_CONVERSATION_TARGET;
  }

  get botType() {
    return INBOUND_BOT_TYPE;
  }

  get objectType() {
    return objectTypes.inboundCustomBot;
  }

  get matchBehavior() {
    return matchBehaviors.transient;
  }

  get titleTranslationKey() {
    return getApp().canUseStandalone
      ? 'operator.custom-bot.trigger.inbound-standalone.title'
      : 'operator.custom-bot.trigger.inbound.title';
  }

  get descriptionTranslationKey() {
    return getApp().canUseStandalone
      ? 'operator.custom-bot.trigger.inbound-standalone.description'
      : 'operator.custom-bot.trigger.inbound.description';
  }

  get icon(): InterfaceIconName {
    return 'wave';
  }

  get EditorConfigClass() {
    let app = getApp();
    if (app.canUseStandalone) {
      return StandaloneInboundBotEditorConfig;
    } else {
      return InboundBotEditorConfig;
    }
  }

  get requiredBillingFeature() {
    return 'bot_workflows';
  }

  get triggerContextType() {
    return AT_CONVERSATION_START;
  }

  get workflowsSetupTriggerType(): string[] {
    return [WORKFLOWS_SETUP_TRIGGER_START_CONVERSATION, WORKFLOWS_SETUP_TRIGGER_CUSTOMER_FACING];
  }
}
