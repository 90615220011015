/* RESPONSIBLE TEAM: team-standalone */

// In the backend, these are defined in app/lib/standalone/constants/integration_states.rb
export enum IntegrationState {
  NotConfigured = -1,
  Created = 0,
  Paused = 1,
  Enabled = 2,
}

export type StandalonePlatform =
  | 'zendesk'
  | 'salesforce'
  | 'hubspot'
  | 'freshdesk'
  | 'front'
  | 'amazon_connect'
  | 'help_scout'
  | 'servicenow'
  | 'gorgias'
  | 'zoho_desk';

export const RESPONSE_PROVIDED_ERRORS = [400, 401, 403, 404, 422, 424];
export const StandalonePlatformToIcon: Record<StandalonePlatform, string | undefined> = {
  zendesk: 'zendesk',
  salesforce: 'salesforce',
  hubspot: 'hubspot',
  freshdesk: undefined,
  front: undefined,
  amazon_connect: undefined,
  help_scout: undefined,
  servicenow: undefined,
  gorgias: undefined,
  zoho_desk: undefined,
};
