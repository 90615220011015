/* RESPONSIBLE TEAM: team-standalone */

import { tracked } from '@glimmer/tracking';
import {
  REQUIRED_ATTRIBUTES,
  type UserField,
  type TicketField,
  OrganizationField,
  type FieldType,
} from './common';

type ZendeskAdmin = {
  id: number;
  name: string;
  email: string;
  photo: string;
};

export type ZendeskTicketsSetupData = {
  admins: Array<ZendeskAdmin>;
  email_addresses: Array<string>;
  live_trigger: { id?: number };
};

export class ZendeskDataFields {
  @tracked userFields: UserField[] = [];
  @tracked ticketFields: TicketField[] = [];
  @tracked organizationFields: OrganizationField[] = [];

  constructor(inputs: {
    userFields: UserField[];
    ticketFields: TicketField[];
    organizationFields: OrganizationField[];
  }) {
    this.userFields = this.requiredUserFields.concat(inputs.userFields);
    this.ticketFields = this.requiredTicketFields.concat(inputs.ticketFields);
    this.organizationFields = this.requiredOrganizationFields.concat(inputs.organizationFields);
  }

  get requiredUserFields(): UserField[] {
    return REQUIRED_ATTRIBUTES.userFields.map((field) => {
      return {
        id: field,
        name: this.titleCase(field),
        type: 'string',
        required: true,
      };
    });
  }

  get requiredOrganizationFields(): OrganizationField[] {
    return REQUIRED_ATTRIBUTES.organizationFields.map((field) => {
      return new OrganizationField({
        id: field.platformIdentifier,
        name: this.titleCase(field.platformIdentifier),
        type: 'text',
        required: true,
        intercom_sda: {
          identifier: field.intercomAttribute,
          type: field.intercomFieldType,
        },
      });
    });
  }

  get requiredTicketFields(): TicketField[] {
    let fields = REQUIRED_ATTRIBUTES.ticketFields.map((field) => {
      return {
        id: field,
        name: this.titleCase(field),
        type: 'string' as FieldType,
        required: true,
      };
    });

    fields.push({
      id: 'tags',
      name: this.titleCase('tags'),
      type: 'tagger' as FieldType,
      required: true,
    });

    return fields;
  }

  titleCase(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
