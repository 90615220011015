/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable no-restricted-imports */
import {
  NEW_ZENDESK_SUNSHINE_CONVERSATION_TARGET,
  NEW_ZENDESK_SUNSHINE_CONVERSATION_TRIGGER,
  AT_CONVERSATION_START,
  TRIGGERABLE_BOT_TYPE,
  EVERYONE_PREDICATE,
  WORKFLOWS_SETUP_TRIGGER_START_CONVERSATION,
} from 'embercom/lib/operator/custom-bots/constants';
import {
  matchBehaviors,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import NewZendeskSunshineConversationBotEditorConfig from 'embercom/objects/visual-builder/configuration/editor/new-zendesk-sunshine-conversation';

import CustomBotConfig from '../custom-bot';
import type { BotConfigTarget } from '../configuration';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export default class NewZendeskSunshineConversationBotConfig extends CustomBotConfig {
  get rolePredicateGroup() {
    return { predicates: [EVERYONE_PREDICATE] };
  }

  get target(): BotConfigTarget {
    return NEW_ZENDESK_SUNSHINE_CONVERSATION_TARGET;
  }

  get botType() {
    return TRIGGERABLE_BOT_TYPE;
  }

  get objectType() {
    return objectTypes.triggerableCustomBot;
  }

  get trigger() {
    return NEW_ZENDESK_SUNSHINE_CONVERSATION_TRIGGER;
  }

  get matchBehavior() {
    return matchBehaviors.transient;
  }

  get titleTranslationKey() {
    return 'operator.custom-bot.trigger.new-zendesk-sunshine-conversation.title';
  }

  get descriptionTranslationKey() {
    return 'operator.custom-bot.trigger.new-zendesk-sunshine-conversation.description';
  }

  get icon(): InterfaceIconName {
    return 'messenger';
  }

  get EditorConfigClass() {
    return NewZendeskSunshineConversationBotEditorConfig;
  }

  visibleToApp(app: any) {
    return app.canUseStandalone as boolean;
  }

  get selectableCustomBotType() {
    return true;
  }

  get requiredBillingFeature() {
    return 'bot_workflows';
  }

  get triggerContextType() {
    return AT_CONVERSATION_START;
  }

  get workflowsSetupTriggerType(): string[] {
    return [WORKFLOWS_SETUP_TRIGGER_START_CONVERSATION];
  }
}
