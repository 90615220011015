/* RESPONSIBLE TEAM: team-workflows */
import {
  AT_CONVERSATION_START,
  NEW_PHONE_CALL_TARGET,
  NEW_PHONE_CALL_TRIGGER,
  TRIGGERABLE_BOT_TYPE,
  PHONE_ROLE_PREDICATE,
  WORKFLOWS_SETUP_TRIGGER_START_CONVERSATION,
  WORKFLOWS_SETUP_TRIGGER_CUSTOMER_FACING,
} from 'embercom/lib/operator/custom-bots/constants';
import {
  matchBehaviors,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';

import CustomBotConfig, { type ColumnConfigs } from '../custom-bot';
import NewPhoneCallTargetEditorConfig from 'embercom/objects/visual-builder/configuration/editor/new-phone-call-target';
import type { BotConfigTarget } from '../configuration';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export default class NewPhoneCallBotConfig extends CustomBotConfig {
  get rolePredicateGroup() {
    return { predicates: [PHONE_ROLE_PREDICATE] };
  }

  get target(): BotConfigTarget {
    return NEW_PHONE_CALL_TARGET;
  }

  get botType() {
    return TRIGGERABLE_BOT_TYPE;
  }

  get objectType() {
    return objectTypes.triggerableCustomBot;
  }

  get trigger() {
    return NEW_PHONE_CALL_TRIGGER;
  }

  get matchBehavior() {
    return matchBehaviors.transient;
  }

  get titleTranslationKey() {
    return 'operator.custom-bot.trigger.new-phone-call.title';
  }

  get isBeta() {
    return false;
  }

  get isNew() {
    return true;
  }

  get descriptionTranslationKey() {
    return 'operator.custom-bot.trigger.new-phone-call.description';
  }

  get icon(): InterfaceIconName {
    return 'phone';
  }

  get editorConfig() {
    return new this.EditorConfigClass({
      targetChannels: this.customBot?.targetChannels,
      areStepPaywallsActive: this.areStepPaywallsActive,
      workflow: this.workflow,
    });
  }

  get EditorConfigClass() {
    return NewPhoneCallTargetEditorConfig;
  }

  get columnKeys(): (keyof ColumnConfigs)[] {
    let baseColumns: (keyof ColumnConfigs)[] = super.columnKeys;

    return [...baseColumns.slice(0, 2), 'channels', ...baseColumns.slice(2)];
  }

  visibleToApp(app: any) {
    return app.canUsePhoneWorkflowTrigger as boolean;
  }

  get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  get triggerContextType() {
    return AT_CONVERSATION_START;
  }

  get workflowsSetupTriggerType(): string[] {
    return [WORKFLOWS_SETUP_TRIGGER_START_CONVERSATION, WORKFLOWS_SETUP_TRIGGER_CUSTOMER_FACING];
  }
}
