/* RESPONSIBLE TEAM: team-help-desk-experience */
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import RenderablePart, {
  type RenderablePartWireFormat,
} from 'embercom/objects/inbox/renderable-part';
import UserSummary, { type UserSummaryWireFormat } from './user-summary';
import { type RenderableData } from './renderable-data';
import TeamSummary, { type TeamSummaryWireFormat } from './team-summary';
import AdminSummary, { type AdminSummaryWireFormat } from './admin-summary';
import ConversationAttributeSummary, {
  type ConversationAttributeSummaryWireFormat,
} from './conversation-attribute-summary';
import { nanoid } from 'nanoid';
import ConversationTaggings, { type ConversationTaggingsWireFormat } from './conversation-taggings';
import type Tag from './tag';
import type TaggablePart from './taggable-part';
import {
  TicketCategory,
  TicketType,
  type TicketTypeWireFormat,
} from 'embercom/objects/inbox/ticket';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import {
  Channel,
  ChannelData,
  type ChannelDataWireFormat,
} from 'embercom/models/data/inbox/channels';
import type UserComment from './renderable/user-comment';
import ParticipantUserSummary, { firstConversationParticipant } from './participant-user-summary';
import moment from 'moment-timezone';
import { later } from '@ember/runloop';
import LinkedTicketSummary, {
  type LinkedTicketSummaryConstructorFormat,
  type LinkedTicketSummaryWireFormat,
} from 'embercom/objects/inbox/linked-ticket-summary';
import AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import SenderEmailAddressSummary from 'embercom/objects/inbox/sender-email-address-summary';
import WhatsappIntegrationSender from 'embercom/objects/inbox/whatsapp-integration-sender';
import { DEFAULT_TICKET_TYPE_ATTRIBUTES } from 'embercom/lib/inbox/constants';
import ConversationSlaState, {
  type ConversationSlaStateWireFormat,
} from './conversation-sla-state';
import { type RecipientsWireFormat } from 'embercom/lib/composer/recipients';

export enum ConversationState {
  Open = 'open',
  Closed = 'closed',
  Snoozed = 'snoozed',
}

export enum TicketSystemState {
  Submitted = 'submitted',
  InProgress = 'in_progress',
  WaitingOnCustomer = 'waiting_on_customer',
  Resolved = 'resolved',
}

export enum ConversationStarter {
  User = 'user',
  AdminDirectMessageToOne = 'admin_direct_message_to_one',
  CustomBot = 'custom_bot',
  AdminDirectMessageToMany = 'admin_direct_message_to_many',
  OtherOutbound = 'other_outbound',
}

export interface ConversationContructorFormat {
  id: number;
  redacted: boolean;
  renderableParts: Array<RenderablePart>;
  userSummary: UserSummary;
  title?: string;
  nextBreachTime?: Date;
  conversationSlaStates?: ConversationSlaState[];
  state: ConversationState;
  priority: boolean;
  adminAssignee?: AdminSummary;
  teamAssignee?: TeamSummary;
  isLoading?: boolean;
  attributes: ConversationAttributeSummary[];
  taggings?: ConversationTaggings;
  lastSeenByUserAt?: Date;
  ticketType?: TicketType;
  participantSummaries: ParticipantUserSummary[];
  visibleToUser?: boolean;
  canMakeVisible?: boolean;
  canReplyToUser: boolean;
  canShareLinkedTicket?: boolean;
  ticketState?: TicketSystemState;
  ticketCustomStateId?: number;
  ticketId?: string;
  ticketCategory?: TicketCategory;
  ticketCreatedAt?: Date;
  channel: ChannelData;
  latestSocialConversationId?: number;
  mergedIntoConversationId?: number;
  parent?: LinkedTicketSummaryConstructorFormat;
  linkedConversationIds: number[] | null;
  linkedCustomerTicketIds: number[] | null;
  sdkSupportsTicket?: boolean;
  brandName?: string;
  helpCenterId?: string;
  isInboundConversation?: boolean;
  createdAt?: Date;
  companyId?: string;
}

export interface ConversationWireFormat {
  id: number | string;
  redacted: boolean;
  renderable_parts: Array<RenderablePartWireFormat>;
  user_summary: UserSummaryWireFormat;
  admin_assignee?: AdminSummaryWireFormat;
  team_assignee?: TeamSummaryWireFormat;
  state: ConversationState;
  priority: boolean;
  title?: string;
  next_breach_time?: string;
  conversation_sla_states?: ConversationSlaStateWireFormat[];
  attributes: ConversationAttributeSummaryWireFormat[];
  taggings?: ConversationTaggingsWireFormat;
  last_seen_by_user_at?: string;
  ticket_type?: TicketTypeWireFormat;
  participant_summaries?: UserSummaryWireFormat[];
  visible_to_user?: boolean;
  can_reply_to_user: boolean;
  ticket_state?: TicketSystemState;
  ticket_custom_state_id?: number;
  ticket_id?: string;
  ticket_category?: TicketCategory;
  ticket_created_at?: string;
  channel: ChannelDataWireFormat;
  latest_social_conversation_id?: number;
  merged_into_conversation_id?: number;
  parent?: LinkedTicketSummaryWireFormat;
  linked_conversations?: LinkedTicketSummaryWireFormat[];
  linked_conversation_ids: number[] | null;
  linked_customer_ticket_ids: number[] | null;
  sdk_supports_ticket?: boolean;
  brand_name?: string;
  help_center_id?: string;
  is_inbound_conversation?: boolean;
  can_make_visible?: boolean;
  can_share_linked_ticket?: boolean;
  created_at?: string;
  company_id?: string;
}

export type MessageSender =
  | AdminWithPermissions
  | SenderEmailAddressSummary
  | WhatsappIntegrationSender;

export type LoadedConversation = Conversation & { isLoading: false };

export default class Conversation {
  readonly id: number;
  readonly ticketId?: string;
  readonly sdkSupportsTicket?: boolean;
  readonly brandName?: string;
  readonly helpCenterId?: string;
  readonly isInboundConversation?: boolean;
  readonly ticketCategory?: TicketCategory;
  readonly companyId?: string;

  @tracked redacted: boolean;
  @tracked userSummary: UserSummary;
  @tracked adminAssignee?: AdminSummary;
  @tracked teamAssignee?: TeamSummary;
  @tracked nextBreachTime?: Date;
  @tracked conversationSlaStates?: ConversationSlaState[];
  @tracked state: ConversationState;
  @tracked priority: boolean;
  @tracked title?: string;
  @tracked isLoading = false;
  @tracked attributes: ConversationAttributeSummary[];
  @tracked isRead = false;
  @tracked lastSeenByUserAt?: Date;
  @tracked ticketType?: TicketType;
  @tracked participantSummaries: ParticipantUserSummary[];
  @tracked visibleToUser?: boolean;
  @tracked canMakeVisible?: boolean;
  @tracked canReplyToUser: boolean;
  @tracked canShareLinkedTicket?: boolean;
  @tracked ticketState?: TicketSystemState;
  @tracked ticketCustomStateId?: number;
  @tracked channel: ChannelData;
  @tracked latestSocialConversationId?: number;
  @tracked mergedIntoConversationId?: number;
  @tracked parent?: LinkedTicketSummary | LinkedTicketSummaryConstructorFormat;
  @tracked linkedConversationIds: number[] | null;
  @tracked linkedCustomerTicketIds: number[] | null;
  @tracked createdAt?: Date;
  @tracked ticketCreatedAt?: Date;
  @tracked isSideConversation?: boolean;

  @tracked socialPreventRepliesLastUpdatedAt: number | undefined;
  _preventRepliesTaskId: ReturnType<typeof later> | undefined;

  @tracked committedParts: RenderablePart[] = A([]);
  @tracked pendingParts: RenderablePart[] = A([]);

  get linkedCustomerReportIds() {
    return (this.linkedConversationIds || []).concat(this.linkedCustomerTicketIds || []);
  }

  get renderableParts(): Array<RenderablePart> {
    return [...this.committedParts, ...this.pendingParts];
  }

  get hasPriority() {
    return this.priority !== undefined;
  }

  get isOpen() {
    return this.state === ConversationState.Open;
  }

  get isSnoozed() {
    return this.state === ConversationState.Snoozed;
  }

  get isClosed() {
    return this.state === ConversationState.Closed;
  }

  get isReplyable(): boolean {
    return this.canReplyToUser;
  }

  get isTicket() {
    return !!this.ticketType;
  }

  get isChild(): boolean {
    return !!this.parent;
  }

  get isRedacted() {
    return this.redacted;
  }

  get isNoteOnlyChildTicket(): boolean {
    return this.isChild && !this.isReplyable;
  }

  get isUnsharedNonChildTicket(): boolean {
    return !this.isChild && !this.isReplyable;
  }

  get initialChannel() {
    // TODO: should be first part not first user part, but channel currently only populated on user parts
    let userComment = this.firstUserComment?.renderableData;
    if (userComment) {
      return (userComment as UserComment).channel;
    } else if (this.isWhatsappOutboundConversation) {
      return Channel.Whatsapp;
    }
    return Channel.Desktop;
  }

  get currentChannel() {
    let userComment = this.lastUserComment?.renderableData;
    if (userComment) {
      return (userComment as UserComment).channel;
    } else if (this.isWhatsappOutboundConversation) {
      return Channel.Whatsapp;
    }
    return Channel.Desktop;
  }

  get isCustomerTicket(): boolean {
    return this.ticketCategory === TicketCategory.Request;
  }

  get isTrackerTicket(): boolean {
    return this.ticketCategory === TicketCategory.Tracker;
  }

  get isBackOfficeTicket(): boolean {
    return this.ticketCategory === TicketCategory.Task;
  }

  get isMerged(): boolean {
    return !!this.mergedIntoConversationId;
  }

  get attributesById() {
    return this.attributes.reduce(
      (map: Record<string, ConversationAttributeSummary>, attribute) => {
        map[attribute.descriptor.id] = attribute;
        return map;
      },
      {},
    );
  }

  lastUserPartOlderThan(limit: number) {
    if (this.olderThan(this.lastUserComment?.createdAt, limit)) {
      return true;
    }
    if (
      !this.lastUserComment &&
      (this.oldestPartLongerThan(limit) || this.isWhatsappOutboundConversation)
    ) {
      return true;
    }
    this.runTaskForPreventReplies(limit);
    return false;
  }

  olderThan(date: Date | undefined, milliseconds: number) {
    return moment(date).isBefore(moment().subtract(milliseconds, 'ms'));
  }

  oldestPartLongerThan(milliseconds: number): boolean {
    let oldestPartCreatedAt = this.renderableParts[0]?.createdAt;
    return this.olderThan(oldestPartCreatedAt, milliseconds);
  }

  scheduledPreventRepliesTask(): boolean {
    return this._preventRepliesTaskId !== undefined;
  }

  runTaskForPreventReplies(replyLimit: number) {
    if (this.scheduledPreventRepliesTask()) {
      return;
    }
    let timeFromLastUserReply = moment().diff(moment(this.lastUserComment?.createdAt));
    let timeToReplyLimit = replyLimit - timeFromLastUserReply + 500; // milliseconds

    this._preventRepliesTaskId = later(
      this,
      () => {
        this._preventRepliesTaskId = undefined;
        this.socialPreventRepliesLastUpdatedAt = Date.now();
      },
      timeToReplyLimit,
    );
  }

  constructor(inputs: ConversationContructorFormat) {
    let {
      id,
      redacted,
      renderableParts,
      userSummary,
      title,
      nextBreachTime,
      conversationSlaStates,
      state,
      priority,
      adminAssignee,
      teamAssignee,
      isLoading,
      attributes,
      lastSeenByUserAt,
      ticketType,
      participantSummaries,
      visibleToUser,
      canMakeVisible,
      canReplyToUser,
      canShareLinkedTicket,
      ticketState,
      ticketCustomStateId,
      ticketId,
      ticketCategory,
      channel,
      latestSocialConversationId,
      mergedIntoConversationId,
      parent,
      sdkSupportsTicket,
      brandName,
      helpCenterId,
      isInboundConversation,
      linkedConversationIds,
      linkedCustomerTicketIds,
      createdAt,
      ticketCreatedAt,
      companyId,
    } = inputs;
    this.id = id;
    this.redacted = redacted;
    this.committedParts = renderableParts;
    this.userSummary = userSummary;
    this.title = title;
    this.nextBreachTime = nextBreachTime;
    this.conversationSlaStates = conversationSlaStates;
    this.state = state;
    this.adminAssignee = adminAssignee;
    this.teamAssignee = teamAssignee;
    this.priority = priority;
    this.isLoading = isLoading || false;
    this.attributes = attributes;
    this.lastSeenByUserAt = lastSeenByUserAt;
    this.ticketType = ticketType;
    this.participantSummaries = participantSummaries;
    this.visibleToUser = visibleToUser;
    this.canMakeVisible = canMakeVisible;
    this.canReplyToUser = canReplyToUser;
    this.canShareLinkedTicket = canShareLinkedTicket;
    this.ticketState = ticketState;
    this.ticketCustomStateId = ticketCustomStateId;
    this.ticketId = ticketId;
    this.ticketCategory = ticketCategory;
    this.channel = channel;
    this.latestSocialConversationId = latestSocialConversationId;
    this.mergedIntoConversationId = mergedIntoConversationId;
    this.parent = parent;
    this.sdkSupportsTicket = sdkSupportsTicket;
    this.brandName = brandName;
    this.helpCenterId = helpCenterId;
    this.isInboundConversation = isInboundConversation;
    this.linkedConversationIds = linkedConversationIds;
    this.linkedCustomerTicketIds = linkedCustomerTicketIds;
    this.createdAt = createdAt ?? undefined;
    this.ticketCreatedAt = ticketCreatedAt ?? undefined;
    this.companyId = companyId;
  }

  static deserialize(json: ConversationWireFormat) {
    let renderableParts = json.renderable_parts.map(RenderablePart.deserialize);
    let userSummary = UserSummary.deserialize(json.user_summary);
    let nextBreachTime = json.next_breach_time ? new Date(json.next_breach_time) : undefined;
    let conversationSlaStates = json.conversation_sla_states
      ? json.conversation_sla_states.map(ConversationSlaState.deserialize)
      : undefined;
    let createdAt = json.created_at ? new Date(json.created_at) : undefined;

    let adminAssignee = json.admin_assignee
      ? AdminSummary.deserialize(json.admin_assignee)
      : undefined;

    let teamAssignee = json.team_assignee ? TeamSummary.deserialize(json.team_assignee) : undefined;
    let taggings = json.taggings ? ConversationTaggings.deserialize(json.taggings) : undefined;
    let attributes = (json.attributes || []).map((attribute) =>
      ConversationAttributeSummary.deserialize(attribute),
    );

    let lastSeenByUserAt = json.last_seen_by_user_at
      ? new Date(json.last_seen_by_user_at)
      : undefined;

    let ticketType = json.ticket_type ? TicketType.deserialize(json.ticket_type) : undefined;
    let participantSummaries = json.participant_summaries
      ? json.participant_summaries.compact().map(ParticipantUserSummary.deserialize)
      : undefined;

    let visibleToUser = json.visible_to_user;
    let canMakeVisible = json.can_make_visible;
    let canReplyToUser = json.can_reply_to_user;
    let canShareLinkedTicket = json.can_share_linked_ticket;
    let channel = ChannelData.deserialize(json.channel);
    let linkedConversationIds = json.linked_conversation_ids;
    let linkedCustomerTicketIds = json.linked_customer_ticket_ids;

    let parent = json.parent ? LinkedTicketSummary.deserialize(json.parent) : undefined;

    let sdkSupportsTicket = json.sdk_supports_ticket;
    let brandName = json.brand_name;
    let helpCenterId = json.help_center_id;
    let ticketCreatedAt = json.ticket_created_at ? new Date(json.ticket_created_at) : undefined;
    let companyId = json.company_id;

    return new Conversation({
      id: Number(json.id),
      redacted: json.redacted,
      renderableParts,
      userSummary,
      title: json.title,
      nextBreachTime,
      conversationSlaStates,
      state: json.state,
      priority: json.priority,
      taggings,
      adminAssignee,
      teamAssignee,
      attributes,
      lastSeenByUserAt,
      ticketType,
      participantSummaries: participantSummaries ?? [],
      visibleToUser,
      canMakeVisible,
      canReplyToUser,
      canShareLinkedTicket,
      ticketState: json?.ticket_state,
      ticketCustomStateId: json?.ticket_custom_state_id,
      ticketId: json?.ticket_id,
      ticketCategory: json?.ticket_category,
      channel,
      latestSocialConversationId: json?.latest_social_conversation_id,
      mergedIntoConversationId: json?.merged_into_conversation_id,
      parent,
      sdkSupportsTicket,
      brandName,
      helpCenterId,
      isInboundConversation: json.is_inbound_conversation,
      linkedConversationIds,
      linkedCustomerTicketIds,
      createdAt,
      ticketCreatedAt,
      companyId,
    });
  }

  get lastPart(): RenderablePart | undefined {
    if (this.renderableParts.length) {
      return this.renderableParts[this.renderableParts.length - 1];
    } else {
      return undefined;
    }
  }

  get isWhatsappOutboundConversation(): boolean {
    return this.renderableParts[0]?.renderableType === RenderableType.Whatsapp;
  }

  get user(): UserSummary {
    return this.userSummary;
  }

  get lastAdminMentionedPart(): undefined {
    return undefined;
  }

  get waitingSince(): undefined {
    return undefined;
  }

  get hasMultipleParticipants() {
    return this.participantSummaries.length > 1;
  }

  get humanAdminComments(): Array<RenderablePart> {
    if (!this.renderableParts.length) {
      return [];
    }

    return this.renderableParts.filter(
      (part) =>
        part.renderableType === RenderableType.AdminComment ||
        part.renderableType === RenderableType.Chat ||
        part.renderableType === RenderableType.Email,
    );
  }

  get userComments(): Array<RenderablePart> {
    if (!this.renderableParts.length) {
      return [];
    }

    return this.renderableParts.filter(
      (part) =>
        part.renderableType === RenderableType.UserComment ||
        part.renderableType === RenderableType.UserEmailComment,
    );
  }

  get firstUserComment(): RenderablePart | undefined {
    return this.userComments[0];
  }

  get lastUserComment(): RenderablePart | undefined {
    let userComments = this.userComments;

    return userComments[userComments.length - 1];
  }

  get lastParticipantComment(): RenderablePart | undefined {
    let userComments = this.userComments.filter((part) =>
      this.participantSummaries.find(
        (participant) => participant.id === (part.renderableData as UserComment).userSummary?.id,
      ),
    );

    return userComments[userComments.length - 1];
  }

  get lastCommentIsANote(): boolean {
    if (!this.renderableParts.length) {
      return true;
    }

    let commentOrNoteParts = this.commentOrNoteParts;

    if (commentOrNoteParts.length === 0) {
      return false;
    }

    return (
      commentOrNoteParts[commentOrNoteParts.length - 1].renderableType ===
        RenderableType.AdminNote ||
      commentOrNoteParts[commentOrNoteParts.length - 1].renderableType === RenderableType.BotNote
    );
  }

  get commentOrNoteParts(): Array<RenderablePart> {
    return this.renderableParts.filter(
      ({ renderableType }) =>
        renderableType === RenderableType.AdminComment ||
        renderableType === RenderableType.AdminNote ||
        renderableType === RenderableType.UserComment ||
        renderableType === RenderableType.Chat ||
        renderableType === RenderableType.UserEmailComment ||
        renderableType === RenderableType.Email ||
        renderableType === RenderableType.BotComment ||
        renderableType === RenderableType.BotNote,
    );
  }

  get commentParts(): Array<RenderablePart> {
    return this.renderableParts.filter(
      ({ renderableType }) =>
        renderableType === RenderableType.AdminComment ||
        renderableType === RenderableType.UserComment ||
        renderableType === RenderableType.Chat ||
        renderableType === RenderableType.UserEmailComment ||
        renderableType === RenderableType.Email ||
        renderableType === RenderableType.BotComment,
    );
  }

  get firstParticipant(): ParticipantUserSummary {
    return firstConversationParticipant(this.participantSummaries) ?? this.user;
  }

  get otherParticipants() {
    if (!this.firstParticipant) {
      return;
    }

    return this.participantSummaries?.without(this.firstParticipant);
  }

  get otherParticipantsCount() {
    return this.otherParticipants?.length;
  }

  get lastRenderablePart() {
    return this.renderableParts[this.renderableParts.length - 1];
  }

  get lastRenderableSummaryPart(): RenderablePart | undefined {
    for (let i = this.renderableParts.length - 1; i >= 0; i--) {
      if (this.renderableParts[i].renderableInSummaryList) {
        return this.renderableParts[i];
      }
    }
    return undefined;
  }

  get allHumanCommentParts() {
    return this.commentParts
      .sortBy('createdAt')
      .filter((part) => part.id !== 0 && part.renderableType !== RenderableType.BotComment);
  }

  get lastHumanCommentPart() {
    return this.allHumanCommentParts.lastObject;
  }

  get lastUpdated(): Date {
    return this.lastRenderableSummaryPart?.createdAt ?? this.lastRenderablePart.createdAt;
  }

  isLastPart(type: RenderableType) {
    return this.lastRenderablePart?.renderableType === type;
  }

  isLastPartCreatedWithin({ seconds }: { seconds: number }) {
    if (!this.lastRenderablePart) {
      return false;
    }

    return moment().diff(moment(this.lastRenderablePart.createdAt), 'second') <= seconds;
  }

  addPendingPart(content: RenderableData, createdAt = new Date()): RenderablePart {
    let part = new RenderablePart(0, content.renderableType, content, createdAt, 0, 0, nanoid());
    this.pendingParts.pushObject(part);
    return part;
  }

  get ticketTitle() {
    if (!this.isTicket) {
      return '';
    }

    let titleAttribute = this.attributes?.find(
      (attr) => attr.descriptor.name === DEFAULT_TICKET_TYPE_ATTRIBUTES.TITLE,
    );

    if (titleAttribute) {
      return titleAttribute.value as string;
    }
    return this.ticketType?.name;
  }

  get ticketDescription() {
    if (!this.isTicket) {
      return '';
    }

    let descriptionAttribute = this.attributes?.find(
      (attr) => attr.descriptor.name === DEFAULT_TICKET_TYPE_ATTRIBUTES.DESCRIPTION,
    );

    if (descriptionAttribute) {
      return descriptionAttribute.value;
    }
    return '';
  }

  pushPendingPart(part: RenderablePart) {
    this.pendingParts.pushObject(part);
    return part;
  }

  removePendingPart(part: RenderablePart) {
    this.pendingParts.removeObject(part);
  }

  commitPendingPart(pendingPart: RenderablePart, createdParts: RenderablePart[]) {
    this.pendingParts.removeObject(pendingPart);
    this.committedParts.pushObjects(createdParts);
  }

  // Will be useful when the API call to change priority is happening.
  optimisticPriorityUpdate(priority: boolean) {
    this.priority = priority;
  }

  updateTagsForRenderablePart(entityId: number | null, tags: Array<Tag>) {
    let part = this.committedParts.find((p) => p.entityId === entityId);

    if (part) {
      let userContent = (part as TaggablePart).renderableData;
      userContent.tags = tags;
    }
  }

  addTagToRenderablePart(entityId: number, tag: Tag) {
    let part = this.committedParts.find((p) => p.entityId === entityId) as TaggablePart;

    if (part) {
      // If tag is already present, don't add it again to avoid duplicates
      //
      // We're using name and not id because when adding a new tag optimistically the tag might not
      // have an id yet.
      if (
        part.renderableData.tags.find((t: Tag) => t.name.toLowerCase() === tag.name.toLowerCase())
      ) {
        return;
      }

      part.renderableData.tags = [...part.renderableData.tags, tag];
    }
  }

  removeTagFromRenderablePart(entityId: number, tag: Tag) {
    let part = this.committedParts.find((p) => p.entityId === entityId) as TaggablePart;

    if (part) {
      part.renderableData.tags = part.renderableData.tags.filter((t: Tag) => t.id !== tag.id);
    }
  }

  setTagIdForRenderablePart(entityId: number, tagName: string, tagId: string) {
    let part = this.committedParts.find((p) => p.entityId === entityId) as TaggablePart;

    if (part) {
      let tag = part.renderableData.tags.find((tag: Tag) => tag.name === tagName);

      if (tag) {
        tag.id = tagId;
      }
    }
  }

  updatePart(part: RenderablePart, updatedPart: RenderablePart) {
    let partIndex = this.committedParts.findIndex((p) => p.id === part.id);
    if (partIndex > -1) {
      this.committedParts.removeAt(partIndex);
      this.committedParts.insertAt(partIndex, updatedPart);
    }
  }

  updateLastSeenByUserAt(to = new Date()) {
    this.lastSeenByUserAt = to;
  }

  addTicket(ticketType: TicketType, attributes: ConversationAttributeSummary[]) {
    this.ticketType = ticketType;
    this.attributes = attributes;
  }

  setTitle(title: string) {
    this.title = title;
  }

  /**
   * SideConversation objects get deserialized as Conversations in some instances so components (i.e. ConversationStream) can easily render it.
   * Enabling a conversation to set whether it's a side conversation helps us from having to drill isSideConversation prop down the component tree.
   * The flag also allows us to easily change UI behaviour for side conversations.
   * @param isSideConversation
   */
  setIsSideConversation(isSideConversation: boolean) {
    this.isSideConversation = isSideConversation;
  }

  hasAttributeForDescriptor(id: number | string) {
    return this.attributes.any((attribute) => attribute.descriptor.id === id);
  }

  addAttribute(attribute: ConversationAttributeSummary) {
    if (this.hasAttributeForDescriptor(attribute.descriptor.id)) {
      return;
    }

    this.attributes = [...this.attributes, attribute];
  }

  hasPartWithUUID(uuid: string | undefined): boolean {
    if (!uuid) {
      return false;
    }

    return this.renderableParts.any((part) => part.clientAssignedUuid === uuid);
  }
}

export class NewConversation {
  id = undefined;
  isLoading = false;
  socialPreventRepliesLastUpdatedAt = undefined;
  latestSocialConversationId = undefined;
  mergedIntoConversationId = undefined;
  lastPart = undefined;
  isReplyable = true;
  isUnsharedNonChildTicket = false;
  isNoteOnlyChildTicket = false;
  isTicket = false;
  committedParts: RenderablePart[] = [];
  ticketState = undefined;
  ticketCustomStateId = undefined;
  ticketCategory = undefined;
  isClosed = false;
  isInboundConversation = false;
  canShareLinkedTicket = false;
  isTrackerTicket = false;
  isRecipientLoadedFromQueryParams = false;
  linkedConversationIds = undefined;
  linkedCustomerTicketIds = undefined;

  @tracked participants: UserSummary[] = [];
  @tracked recipients?: RecipientsWireFormat;
  @tracked adminAssignee?: AdminSummary;
  @tracked teamAssignee?: TeamSummary;
  @tracked title?: string;
  @tracked sender?: MessageSender;

  @tracked renderableParts: RenderablePart[] = [];

  addRenderablePart(part: RenderablePart) {
    this.renderableParts = [...this.renderableParts, part];
  }

  get userSummary() {
    return this.participants.length ? this.participants[0] : undefined;
  }

  get participantSummaries() {
    return this.participants;
  }

  get firstParticipant() {
    return this.userSummary;
  }

  get otherParticipants() {
    if (!this.firstParticipant) {
      return;
    }

    return this.participants.without(this.firstParticipant);
  }

  get serialize() {
    return {
      adminAssignee: this.adminAssignee,
      teamAssignee: this.teamAssignee,
      title: this.title,
      sender: this.sender,
    };
  }

  get linkedCustomerReportIds() {
    return (this.linkedConversationIds || []).concat(this.linkedCustomerTicketIds || []);
  }

  // when draft conversation is retrieved from the local storage, it is retrieved as a json object
  // so we need to cast the fields again to their types
  static deserialize(jsonObject: any, teammateId: number) {
    let newConversation = new NewConversation();
    newConversation.title = jsonObject.title;

    let adminAssignee = jsonObject.adminAssignee;
    if (adminAssignee) {
      newConversation.adminAssignee = new AdminSummary(
        adminAssignee.id,
        adminAssignee.name,
        adminAssignee.imageURL,
        adminAssignee.isImpersonating,
        adminAssignee.alias_enabled,
        adminAssignee.isAway,
        adminAssignee.openCount,
        {
          tickets: adminAssignee.countMetadata?.tickets,
          conversations: adminAssignee.countMetadata?.conversations,
        },
        adminAssignee.email,
      );
    }

    let teamSummary = jsonObject.teamAssignee;
    if (teamSummary) {
      newConversation.teamAssignee = new TeamSummary(
        teamSummary.id,
        teamSummary.name,
        teamSummary.icon,
        teamSummary.count,
      );
    }

    let sender = jsonObject.sender;
    if (sender) {
      if (sender.id === teammateId) {
        newConversation.sender = new AdminWithPermissions(
          sender.id,
          sender.name,
          sender.imageURL,
          sender.permissions,
          sender.seats,
          sender.teamIds,
          sender.local,
          sender.isImpersonating,
          sender.count,
          sender.optedIntoInbox2,
        );
      } else if (sender.whatsappAccountId) {
        newConversation.sender = new WhatsappIntegrationSender(
          sender.id,
          sender.phoneNumber,
          sender.whatsappAccountId,
          sender.displayName,
        );
      } else {
        newConversation.sender = new SenderEmailAddressSummary(
          sender.id,
          sender.name,
          sender.email,
          sender.status,
          sender.verified,
          sender.customBounceValid,
          sender.dmarcValid,
        );
      }
    }

    return newConversation;
  }
}
