/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import { getApp, getIntlService, getAttributeService } from 'embercom/lib/container-lookup';
import InserterMenuConfiguration, {
  type EdgeParameters,
  type StepParameters,
  type InserterMenuConfigurationParams,
} from 'embercom/objects/visual-builder/configuration/inserter-menu';
import { inserterMenuItems } from 'embercom/objects/visual-builder/configuration/inserter-menu-items-map';
import {
  stepModelClasses,
  type StepNames,
  stepTypes,
} from 'embercom/objects/visual-builder/configuration-list';
import { assert } from '@ember/debug';
// @ts-ignore
import { cached } from 'tracked-toolbox';
import { tracked } from '@glimmer/tracking';
import { type SyncHasMany } from '@ember-data/model';
import type Step from 'embercom/models/operator/visual-builder/step';
import { IsCustomerFacing } from 'embercom/models/operator/visual-builder/step';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type Group from 'embercom/models/operator/visual-builder/group';
import type IntlService from 'embercom/services/intl';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

type PathConfigParams = {
  path: Group;
  editorState: EditorState;
  entityType?: number;
  trigger?: string;
};

const TICKET_TRIGGER_STEPS = [
  inserterMenuItems.setTicketState,
  inserterMenuItems.setTicketCustomState,
];
const NEW_PHONE_CALL_TRIGGER_STEPS = [
  inserterMenuItems.hangUp,
  inserterMenuItems.holdAndAssign,
  inserterMenuItems.phoneHoldAndAssign,
  inserterMenuItems.forwardCall,
  inserterMenuItems.phoneCallCsatRequest,
  inserterMenuItems.voicemail,
  inserterMenuItems.callback,
];

const INTEGRATION_ACTION_STEPS = [
  inserterMenuItems.sendToHubspot,
  inserterMenuItems.sendToMarketo,
  inserterMenuItems.sendToSalesforce,
  inserterMenuItems.triggerMarketoCampaign,
  inserterMenuItems.createSalesforceCase,
  inserterMenuItems.notifySlackChannel,
  inserterMenuItems.customActionTemplate,
];

const STANDALONE_ZENDESK_MESSENGER_STEPS = [
  inserterMenuItems.handoffToZendeskTicket,
  inserterMenuItems.handoffToZendeskAgent,
];

const STANDALONE_INTERCOM_MESSENGER_STEPS = [
  inserterMenuItems.handoffToUrl,
  inserterMenuItems.handoffWithJavaScript,
  inserterMenuItems.handoffToSalesforceCase,
  inserterMenuItems.handoffToSalesforceChat,
];

const STANDALONE_SALESFORCE_CASE_STEPS = [inserterMenuItems.handoffToSalesforceTeam];

const STANDALONE_ZENDESK_MESSENGER_ALLOWED_ITEMS = [
  ...STANDALONE_ZENDESK_MESSENGER_STEPS,
  inserterMenuItems.fin,
  inserterMenuItems.attributeCollector,
  inserterMenuItems.chatMessage,
  inserterMenuItems.conditionalBranches,
  inserterMenuItems.freeInput,
  inserterMenuItems.replyButtons,
  inserterMenuItems.setUserDataAttribute,
  inserterMenuItems.setConversationDataAttribute,
  inserterMenuItems.classifyConversationAttribute,
  inserterMenuItems.existingPaths,
  inserterMenuItems.setLanguageOverride,
  inserterMenuItems.setConversationDataAttribute,
  inserterMenuItems.addTagToConversation,
  inserterMenuItems.note,
  inserterMenuItems.summarizeConversation,
  inserterMenuItems.wait,
];

const STANDALONE_ZENDESK_TICKET_ALLOWED_ITEMS = [
  inserterMenuItems.fin,
  inserterMenuItems.chatMessage,
  inserterMenuItems.conditionalBranches,
  inserterMenuItems.freeInput,
  inserterMenuItems.setUserDataAttribute,
  inserterMenuItems.setConversationDataAttribute,
  inserterMenuItems.note,
  inserterMenuItems.summarizeConversation,
  inserterMenuItems.classifyConversationAttribute,
  inserterMenuItems.existingPaths,
  inserterMenuItems.setLanguageOverride,
  inserterMenuItems.handoffToZendeskAgent,
  inserterMenuItems.wait,
];

const STANDALONE_SALESFORCE_CASE_ALLOWED_ITEMS = [
  ...STANDALONE_SALESFORCE_CASE_STEPS,
  inserterMenuItems.fin,
  inserterMenuItems.chatMessage,
  inserterMenuItems.conditionalBranches,
  inserterMenuItems.freeInput,
  inserterMenuItems.setUserDataAttribute,
  inserterMenuItems.setConversationDataAttribute,
  inserterMenuItems.note,
  inserterMenuItems.summarizeConversation,
  inserterMenuItems.classifyConversationAttribute,
  inserterMenuItems.existingPaths,
  inserterMenuItems.setLanguageOverride,
  inserterMenuItems.wait,
];

const STANDALONE_INTERCOM_MESSENGER_ALLOWED_ITEMS = [
  inserterMenuItems.fin,
  inserterMenuItems.attributeCollector,
  inserterMenuItems.chatMessage,
  inserterMenuItems.conditionalBranches,
  inserterMenuItems.disableComposer,
  inserterMenuItems.freeInput,
  inserterMenuItems.replyButtons,
  inserterMenuItems.setLanguageOverride,
  inserterMenuItems.setUserDataAttribute,
  inserterMenuItems.setConversationDataAttribute,
  inserterMenuItems.note,
  inserterMenuItems.summarizeConversation,
  inserterMenuItems.classifyConversationAttribute,
  inserterMenuItems.existingPaths,
  inserterMenuItems.handoffToZendeskTicket,
  inserterMenuItems.handoffToSalesforceCase,
  inserterMenuItems.handoffToSalesforceChat,
  inserterMenuItems.addTagToConversation,
  inserterMenuItems.handoffToUrl,
  inserterMenuItems.handoffWithJavaScript,
  inserterMenuItems.handoffToZendeskAgent,
  inserterMenuItems.finCustom,
  inserterMenuItems.workflowConnector,
  inserterMenuItems.wait,
];

const FIN_INTENT_DETECTION_TRIGGER_STEPS = [
  inserterMenuItems.finCustom,
  inserterMenuItems.waitForCallback,
];

export default class PathConfig {
  @tracked steps: SyncHasMany<Step>;

  app: $TSFixMe; // App
  intl: IntlService;
  path: Group;
  editorState: EditorState;
  attributeService: $TSFixMe;

  constructor({ path, editorState }: PathConfigParams) {
    this.app = getApp();
    this.intl = getIntlService();
    this.path = path;
    this.steps = this.path.steps;
    this.editorState = editorState;
    this.attributeService = getAttributeService();

    assert('You must specify the path for the path configuration', this.path);
  }

  get excludedFinIntentDetectionWorkflowTriggerSteps() {
    return this.app.canUseFinWorkflowsStepsInAllWorkflows ? [] : FIN_INTENT_DETECTION_TRIGGER_STEPS;
  }

  @cached
  get stepInserterMenu(): InserterMenuConfiguration {
    let options = {
      ...this.stepInserterMenuOptions,
      excludedItems: this.stepInserterMenuOptions.excludedItems || [inserterMenuItems.endBot],
    };

    if (!this.app.hasConsentedToExternalAi && !this.app.hasConsentedToFinIngestion) {
      options.excludedItems.push(inserterMenuItems.finCustom);
    }

    return new InserterMenuConfiguration({
      onSelectItem: this.stepInserterOnSelectItem,
      pathConfig: this,
      ...options,
    });
  }

  @cached
  get pathInserterMenu(): InserterMenuConfiguration {
    let options = {
      ...this.pathInserterMenuOptions,
      excludedItems: this.pathInserterMenuOptions.excludedItems || [],
    };

    if (!this.app.hasConsentedToExternalAi) {
      options.excludedItems.push(inserterMenuItems.finCustom);
    }

    return new InserterMenuConfiguration({
      existingPaths: this.editorState.workflow.groups.reject((group) => group === this.path),
      onSelectItem: this.pathInserterOnSelectItem,
      pathConfig: this,
      ...options,
    });
  }

  stepInserterOnSelectItem = ({
    ModelClass,
    stepCreationParams,
    analyticsData,
  }: StepParameters) => {
    let params = {
      group: this.path,
      ModelClass,
      stepCreationParams,
      analyticsData,
    };

    if (ModelClass.isStepGroupEnding && this.path.hasGroupEndingStep) {
      this.editorState.replaceStep({
        replaceAtIndex: Number(this.steps.length) - 1,
        ...params,
      });
    } else {
      this.editorState.createStep({
        insertAtIndex: !this.path.hasGroupEndingStep
          ? Number(this.steps.length)
          : Number(this.steps.length) - 1,
        ...params,
      });
    }
  };

  _pathInserterOnSelectItem(
    { ModelClass, stepCreationParams, customItemHandler, analyticsData }: StepParameters,
    { connectionPoint }: EdgeParameters,
  ) {
    let { editorState } = this;
    if (customItemHandler) {
      return customItemHandler({ connectionPoint, editorState });
    }
    return editorState.createGroupFromConnectionPoint({
      StepModelClass: ModelClass,
      stepCreationParams,
      outwardConnectionPoint: connectionPoint,
      analyticsData,
    });
  }

  // A lambda function, in comparison to a method, keeps the `this` scope of the class instance.
  // In order to allow for class-based inheritance we need to use a method, but call it using a lambda.
  pathInserterOnSelectItem = (stepParams: StepParameters, edgeParams: EdgeParameters) =>
    this._pathInserterOnSelectItem.call(this, stepParams, edgeParams);

  get isAddStepEnabled() {
    return true;
  }

  get stepInserterMenuOptions(): Partial<InserterMenuConfigurationParams> {
    return {};
  }

  get pathInserterMenuOptions(): Partial<InserterMenuConfigurationParams> {
    return {};
  }

  canDeleteStep(_step: Step) {
    return (
      this.path.workflow.groups.length === 1 ||
      (this.steps.length as number) > 1 ||
      !this.path.isStart
    );
  }

  deleteStep(step: Step) {
    this.editorState.deleteStep(step);
  }

  maybeCreateDefaultPathEndingStep(insertedItem: any, stepType: StepNames) {
    // Newly created element may not be a group. This happens when an inserter item is handled by a custom handler (such as connecting to an existing group) and the custom handler returns something other than a group object (or null)
    if (
      !insertedItem ||
      (insertedItem.constructor as typeof Group).modelName !== 'operator/visual-builder/group'
    ) {
      return;
    }

    let group = insertedItem as Group;

    if (group.hasGroupEndingStep) {
      return; // This group already has a group ending step
    }

    // Create the handoff step
    this.editorState.createStep({
      group,
      ModelClass: stepModelClasses[stepType],
      insertAtIndex: Number(group.steps.length),
    });
  }
}

export class ButtonPathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
      ],
    };
  }

  canDeleteStep(step: Step) {
    // cannot delete first step of first path
    if (this.path.isStart && this.steps.indexOf(step) === 0) {
      return false;
    }

    return super.canDeleteStep(step);
  }

  get stepInserterMenuOptions() {
    let excludedItems = [
      inserterMenuItems.endBot,
      inserterMenuItems.endHandover,
      inserterMenuItems.answerTerminal,
      ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
      ...TICKET_TRIGGER_STEPS,
      ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ...STANDALONE_ZENDESK_MESSENGER_STEPS,
      ...STANDALONE_INTERCOM_MESSENGER_STEPS,
      ...STANDALONE_SALESFORCE_CASE_STEPS,
    ];

    if (this.path.isStart) {
      excludedItems.push(inserterMenuItems.addTagToConversation);
    }

    return { excludedItems };
  }
}

export class InboundPathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
        ...(this.path.isStart
          ? Object.values(stepTypes).filter((type) => type !== stepTypes.chatMessage)
          : []),
      ],
    };
  }

  get isAddStepEnabled() {
    return true;
  }

  _pathInserterOnSelectItem(stepParams: StepParameters, edgeParams: EdgeParameters) {
    let { connectionPoint } = edgeParams;
    if (
      connectionPoint.type === 'capture_composer_input' &&
      connectionPoint.edge?.toGroup.isPlaceholder
    ) {
      this.editorState.deleteGroup(connectionPoint.edge.toGroup);
    }

    return super._pathInserterOnSelectItem(stepParams, edgeParams);
  }

  canDeleteStep(step: Step) {
    // must have at least one chat message in starting path
    if (
      this.path.isStart &&
      this.path.steps.filterBy('typeKey', stepTypes.chatMessage).length === 1
    ) {
      return false;
    }

    return super.canDeleteStep(step);
  }
}

export class StandaloneInboundPathConfig extends InboundPathConfig {
  @tracked isZendeskConfiguredForIntercomMessenger: boolean;
  @tracked isSalesforceConfigured: boolean;

  constructor({
    path,
    editorState,
    isZendeskConfiguredForIntercomMessenger,
    isSalesforceConfigured,
  }: PathConfigParams & {
    isZendeskConfiguredForIntercomMessenger: boolean;
    isSalesforceConfigured: boolean;
  }) {
    super({ path, editorState });
    this.isZendeskConfiguredForIntercomMessenger = isZendeskConfiguredForIntercomMessenger;
    this.isSalesforceConfigured = isSalesforceConfigured;
  }

  get pathInserterMenuOptions() {
    return {
      allowedItems: STANDALONE_INTERCOM_MESSENGER_ALLOWED_ITEMS.concat([inserterMenuItems.endBot]),
      excludedItems: [],
      onSelectItem: (
        { ModelClass, stepCreationParams, customItemHandler }: StepParameters,
        { connectionPoint }: EdgeParameters,
      ) => {
        let insertedItem = this.pathInserterOnSelectItem(
          { ModelClass, stepCreationParams, customItemHandler },
          { connectionPoint },
        );

        this.maybeCreateDefaultPathEndingStep(insertedItem, this.defaultPathEndingStepType);

        return insertedItem;
      },
    };
  }

  get stepInserterMenuOptions() {
    return {
      allowedItems: STANDALONE_INTERCOM_MESSENGER_ALLOWED_ITEMS,
      excludedItems: [
        ...(this.path.isStart
          ? Object.values(stepTypes).filter((type) => type !== stepTypes.chatMessage)
          : []),
      ],
    };
  }

  get defaultPathEndingStepType() {
    if (this.isZendeskConfiguredForIntercomMessenger) {
      return stepTypes.handoffToZendeskAgent;
      // TODO: Uncomment this when salesforce handoff is ready
      // } else if (this.isSalesforceConfigured) {
      //   return stepTypes.handoffToSalesforceAgent;
    } else {
      return stepTypes.handoffWithJavaScript;
    }
  }
}

export class OutboundPathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
      ],
    };
  }

  get isAddStepEnabled() {
    return !this.path.isStart;
  }

  canDeleteStep(step: Step) {
    // cannot delete any steps in starting path
    if (this.path.isStart) {
      return false;
    }

    return super.canDeleteStep(step);
  }
}

export class ReusablePathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
      ],
    };
  }
}

export class ConversationStartedTargetPathConfig extends PathConfig {
  excludedItems: string[];

  constructor({ path, editorState }: PathConfigParams) {
    super({ path, editorState });
    this.excludedItems = [
      inserterMenuItems.endHandover,
      inserterMenuItems.answerTerminal,
      ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
      ...TICKET_TRIGGER_STEPS,
      ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ...STANDALONE_ZENDESK_MESSENGER_STEPS,
      ...STANDALONE_INTERCOM_MESSENGER_STEPS,
      ...STANDALONE_SALESFORCE_CASE_STEPS,
    ];
  }

  get pathInserterMenuOptions() {
    return { excludedItems: this.excludedItems };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [...this.excludedItems, inserterMenuItems.endBot],
    };
  }
}

export class NewPhoneCallTargetPathConfig extends PathConfig {
  excludedItems: string[];

  constructor({ path, editorState }: PathConfigParams) {
    super({ path, editorState });
    this.excludedItems = [
      inserterMenuItems.answerTerminal,
      inserterMenuItems.fin,
      inserterMenuItems.freeInput,
      inserterMenuItems.triggerWorkflow,
      inserterMenuItems.disableComposer,
      inserterMenuItems.wait,
      inserterMenuItems.snooze,
      inserterMenuItems.conversationRatings,
      inserterMenuItems.endHandover,
      inserterMenuItems.attributeCollector,
      inserterMenuItems.messengerApp,
      inserterMenuItems.sendTicket,
      inserterMenuItems.applyRules,
      inserterMenuItems.customObjectSelector,
      inserterMenuItems.setExpectations,
      inserterMenuItems.summarizeConversation,
      inserterMenuItems.convertToTicket,
      inserterMenuItems.assignConversation,
      inserterMenuItems.closeConversation,
      inserterMenuItems.applyConversationSla,
      inserterMenuItems.finCustom,
      inserterMenuItems.waitForCallback,
      ...TICKET_TRIGGER_STEPS,
      ...STANDALONE_ZENDESK_MESSENGER_STEPS,
      ...STANDALONE_INTERCOM_MESSENGER_STEPS,
      ...STANDALONE_SALESFORCE_CASE_STEPS,
    ];
  }

  get pathInserterMenuOptions() {
    return { excludedItems: this.excludedItems };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [...this.excludedItems, inserterMenuItems.endBot],
    };
  }
}

export class NewCommentTargetPathConfig extends PathConfig {
  excludedItems: string[];

  constructor({ path, editorState }: PathConfigParams) {
    super({ path, editorState });
    this.excludedItems = [
      inserterMenuItems.endHandover,
      inserterMenuItems.answerTerminal,
      inserterMenuItems.classifyConversationAttribute,
      ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
      ...TICKET_TRIGGER_STEPS,
      ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ...STANDALONE_ZENDESK_MESSENGER_STEPS,
      ...STANDALONE_INTERCOM_MESSENGER_STEPS,
      ...STANDALONE_SALESFORCE_CASE_STEPS,
    ];
  }

  get pathInserterMenuOptions() {
    return { excludedItems: this.excludedItems };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [...this.excludedItems, inserterMenuItems.endBot],
    };
  }
}

export class ConversationStatusChangedPathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
      ],
    };
  }
}

export class ConversationAssignmentChangedPathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.answerTerminal,
        inserterMenuItems.endHandover,
        ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.answerTerminal,
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
      ],
    };
  }
}

export class UserInactivityPathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
      ],
    };
  }
}

export class AdminInactivityPathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
      ],
    };
  }
}

export class CustomAnswerPathConfig extends PathConfig {
  get stepInserterMenuOptions() {
    let app = getApp();
    return {
      excludedItems: [
        inserterMenuItems.fin,
        inserterMenuItems.sendTicket,
        ...(app.canUseFeature('mid-path-reusable-workflows')
          ? []
          : [inserterMenuItems.triggerWorkflow]),
        inserterMenuItems.disableComposer,
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        inserterMenuItems.conversationRatings,
        ...FIN_INTENT_DETECTION_TRIGGER_STEPS, // TODO: Support local variables in Custom Answers
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
      ],
    };
  }

  get pathInserterMenuOptions() {
    let app = getApp();
    return {
      excludedItems: [
        inserterMenuItems.fin,
        inserterMenuItems.sendTicket,
        ...(app.canUseFeature('mid-path-reusable-workflows')
          ? []
          : [inserterMenuItems.triggerWorkflow]),
        inserterMenuItems.disableComposer,
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        inserterMenuItems.conversationRatings,
        ...FIN_INTENT_DETECTION_TRIGGER_STEPS, // TODO: Support local variables in Custom Answers
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
      ],
      onSelectItem: (
        { ModelClass, stepCreationParams, customItemHandler }: StepParameters,
        { connectionPoint }: EdgeParameters,
      ) => {
        let insertedItem = this.pathInserterOnSelectItem(
          { ModelClass, stepCreationParams, customItemHandler },
          { connectionPoint },
        );

        this.maybeCreateDefaultPathEndingStep(insertedItem, stepTypes.answerTerminal);

        return insertedItem;
      },
    };
  }

  canDeleteStep(step: Step) {
    // cannot delete first step of first path
    if (this.path.isStart && this.steps.indexOf(step) === 0) {
      return false;
    } else if (
      (this.steps.length as number) > 1 &&
      step.type === 'operator/visual-builder/step/answer-terminal'
    ) {
      return false;
    }

    return super.canDeleteStep(step);
  }

  deleteStep(step: Step) {
    let group = step.group;
    super.deleteStep(step);
    this.maybeCreateDefaultPathEndingStep(group, stepTypes.answerTerminal);
  }
}

export class AiAgentProfilePathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.answerTerminal,
        inserterMenuItems.fin,
        inserterMenuItems.endBot,
        ...FIN_INTENT_DETECTION_TRIGGER_STEPS, // TODO: Support local variables in Ai Agent Profiles Handover
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.answerTerminal,
        inserterMenuItems.fin,
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        ...FIN_INTENT_DETECTION_TRIGGER_STEPS, // TODO: Support local variables in Ai Agent Profiles Handover
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
      ],
    };
  }
}

export class BackgroundTriggerPathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
        ...Object.keys(stepModelClasses).filter(
          (stepType: keyof typeof stepModelClasses) =>
            stepModelClasses[stepType].canBeCustomerFacing === IsCustomerFacing.Yes,
        ),
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        inserterMenuItems.endBot,
        ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
        ...Object.keys(stepModelClasses).filter(
          (stepType: keyof typeof stepModelClasses) =>
            stepModelClasses[stepType].canBeCustomerFacing === IsCustomerFacing.Yes,
        ),
      ],
    };
  }
}

export class NewAdminCommentPathConfig extends BackgroundTriggerPathConfig {
  excludedItems: string[];

  constructor({ path, editorState }: PathConfigParams) {
    super({ path, editorState });
    this.excludedItems = [
      ...(getApp().canUseAiConversationClassification
        ? [inserterMenuItems.classifyConversationAttribute]
        : []),
    ];
  }
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        ...super.pathInserterMenuOptions.excludedItems.filter(
          (step) => step !== stepTypes.disableComposer,
        ),
        ...TICKET_TRIGGER_STEPS,
        ...this.excludedItems,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        ...super.stepInserterMenuOptions.excludedItems.filter(
          (step) => step !== stepTypes.disableComposer,
        ),
        ...TICKET_TRIGGER_STEPS,
        ...this.excludedItems,
      ],
    };
  }
}
export class AdminNoteCreatedPathConfig extends BackgroundTriggerPathConfig {
  @tracked targetContexts: Readonly<TargetContext[]>;

  constructor({
    path,
    editorState,
    targetContexts,
  }: PathConfigParams & { targetContexts: TargetContext[] }) {
    super({ path, editorState });
    this.targetContexts = targetContexts;
  }

  get excludedStepTypes() {
    return Object.keys(stepModelClasses).filter((stepType: keyof typeof stepModelClasses) => {
      let unsupportedContexts = this.targetContexts.filter(
        (context) => !stepModelClasses[stepType].supportedContexts.includes(context),
      );
      return unsupportedContexts.length > 0;
    });
  }

  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        ...super.pathInserterMenuOptions.excludedItems,
        ...TICKET_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
        ...this.excludedStepTypes,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        ...super.stepInserterMenuOptions.excludedItems,
        ...TICKET_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
        ...this.excludedStepTypes,
      ],
    };
  }
}
export class ConversationAttributeChangedPathConfig extends BackgroundTriggerPathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [...super.pathInserterMenuOptions.excludedItems, ...TICKET_TRIGGER_STEPS],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [...super.stepInserterMenuOptions.excludedItems, ...TICKET_TRIGGER_STEPS],
    };
  }
}

export class TicketTriggerPathConfig extends PathConfig {
  excludedItems: string[];
  @tracked targetContexts: Readonly<TargetContext[]>;

  constructor({
    path,
    editorState,
    targetContexts,
  }: PathConfigParams & { targetContexts: TargetContext[] }) {
    super({ path, editorState });
    this.targetContexts = targetContexts;
    this.excludedItems = [
      ...(getApp().canUseAiConversationClassification
        ? [inserterMenuItems.classifyConversationAttribute]
        : []),
    ];
  }

  get excludedStepTypes() {
    let app = getApp();

    if (app.canUseWorkflowsTicketsV3) {
      return Object.keys(stepModelClasses).filter((stepType: keyof typeof stepModelClasses) => {
        let unsupportedContexts = this.targetContexts.filter(
          (context) => !stepModelClasses[stepType].supportedContexts.includes(context),
        );
        return unsupportedContexts.length > 0;
      });
    } else {
      return Object.keys(stepModelClasses).filter(
        (stepType: keyof typeof stepModelClasses) =>
          stepModelClasses[stepType].canBeCustomerFacing === IsCustomerFacing.Yes,
      );
    }
  }

  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
        ...this.excludedStepTypes,
        ...this.excludedItems,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        inserterMenuItems.endBot,
        ...this.excludedFinIntentDetectionWorkflowTriggerSteps,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
        ...this.excludedStepTypes,
        ...this.excludedItems,
      ],
    };
  }
}

export class WebhookReceivedPathConfig extends BackgroundTriggerPathConfig {
  private readonly targetContexts: TargetContext[] = [
    TargetContext.CustomerTicket,
    TargetContext.BackOfficeTicket,
  ];

  get excludedStepTypes() {
    return Object.keys(stepModelClasses).filter((stepType: keyof typeof stepModelClasses) => {
      let unsupportedContexts = this.targetContexts.filter(
        (context) => !stepModelClasses[stepType].supportedContexts.includes(context),
      );
      return unsupportedContexts.length > 0;
    });
  }

  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.workflowConnector,
        ...INTEGRATION_ACTION_STEPS,
        ...super.pathInserterMenuOptions.excludedItems,
        ...this.excludedStepTypes,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.workflowConnector,
        ...INTEGRATION_ACTION_STEPS,
        ...super.stepInserterMenuOptions.excludedItems,
        ...this.excludedStepTypes,
      ],
    };
  }
}

export class AiAgentWorkflowPathConfig extends PathConfig {
  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.fin,
        inserterMenuItems.sendTicket,
        inserterMenuItems.disableComposer,
        inserterMenuItems.endHandover,
        inserterMenuItems.endBot,
        inserterMenuItems.conversationRatings,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
      ],
      onSelectItem: ({ ModelClass, stepCreationParams, analyticsData }: StepParameters) => {
        this.stepInserterOnSelectItem({ ModelClass, stepCreationParams, analyticsData });
        this.maybeCreateDefaultPathEndingStep(this.path, stepTypes.answerTerminal);
      },
    };
  }

  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.fin,
        inserterMenuItems.sendTicket,
        inserterMenuItems.disableComposer,
        inserterMenuItems.endHandover,
        inserterMenuItems.endBot,
        inserterMenuItems.conversationRatings,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...STANDALONE_ZENDESK_MESSENGER_STEPS,
        ...STANDALONE_INTERCOM_MESSENGER_STEPS,
        ...STANDALONE_SALESFORCE_CASE_STEPS,
      ],
      onSelectItem: (
        { ModelClass, stepCreationParams, customItemHandler }: StepParameters,
        { connectionPoint }: EdgeParameters,
      ) => {
        let insertedItem = this.pathInserterOnSelectItem(
          { ModelClass, stepCreationParams, customItemHandler },
          { connectionPoint },
        );

        this.maybeCreateDefaultPathEndingStep(insertedItem, stepTypes.answerTerminal);

        return insertedItem;
      },
    };
  }

  canDeleteStep(step: Step) {
    if (
      ((this.path.steps.length as number) > 1 || this.path.isStart) &&
      step.type === 'operator/visual-builder/step/answer-terminal'
    ) {
      return false;
    }

    return super.canDeleteStep(step);
  }

  deleteStep(step: Step) {
    let group = step.group;
    super.deleteStep(step);
    this.maybeCreateDefaultPathEndingStep(group, stepTypes.answerTerminal);
  }
}

export class NewSalesforceCasePathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      allowedItems: STANDALONE_SALESFORCE_CASE_ALLOWED_ITEMS.concat([inserterMenuItems.endBot]),
      onSelectItem: (
        { ModelClass, stepCreationParams, customItemHandler }: StepParameters,
        { connectionPoint }: EdgeParameters,
      ) => {
        let insertedItem = this.pathInserterOnSelectItem(
          { ModelClass, stepCreationParams, customItemHandler },
          { connectionPoint },
        );

        this.maybeCreateDefaultPathEndingStep(insertedItem, stepTypes.handoffToSalesforceTeam);

        return insertedItem;
      },
    };
  }

  get stepInserterMenuOptions() {
    return {
      allowedItems: STANDALONE_SALESFORCE_CASE_ALLOWED_ITEMS,
    };
  }
}

export class NewZendeskTicketPathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      allowedItems: STANDALONE_ZENDESK_TICKET_ALLOWED_ITEMS.concat([inserterMenuItems.endBot]),
      onSelectItem: (
        { ModelClass, stepCreationParams, customItemHandler }: StepParameters,
        { connectionPoint }: EdgeParameters,
      ) => {
        let insertedItem = this.pathInserterOnSelectItem(
          { ModelClass, stepCreationParams, customItemHandler },
          { connectionPoint },
        );

        this.maybeCreateDefaultPathEndingStep(insertedItem, stepTypes.handoffToZendeskAgent);

        return insertedItem;
      },
    };
  }

  get stepInserterMenuOptions() {
    return {
      allowedItems: STANDALONE_ZENDESK_TICKET_ALLOWED_ITEMS,
    };
  }
}

export class NewZendeskSunshineConversationPathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      allowedItems: STANDALONE_ZENDESK_MESSENGER_ALLOWED_ITEMS.concat([inserterMenuItems.endBot]),
      onSelectItem: (
        { ModelClass, stepCreationParams, customItemHandler }: StepParameters,
        { connectionPoint }: EdgeParameters,
      ) => {
        let insertedItem = this.pathInserterOnSelectItem(
          { ModelClass, stepCreationParams, customItemHandler },
          { connectionPoint },
        );

        this.maybeCreateDefaultPathEndingStep(insertedItem, stepTypes.handoffToZendeskAgent);

        return insertedItem;
      },
    };
  }

  get stepInserterMenuOptions() {
    return {
      allowedItems: STANDALONE_ZENDESK_MESSENGER_ALLOWED_ITEMS,
    };
  }
}

export class StandaloneMessengerConversationStartedPathConfig extends PathConfig {
  @tracked isZendeskConfigured: boolean;
  @tracked isSalesforceConfigured: boolean;

  constructor({
    path,
    editorState,
    isZendeskConfigured,
    isSalesforceConfigured,
  }: PathConfigParams & { isZendeskConfigured: boolean; isSalesforceConfigured: boolean }) {
    super({ path, editorState });
    this.isZendeskConfigured = isZendeskConfigured;
    this.isSalesforceConfigured = isSalesforceConfigured;
  }

  get pathInserterMenuOptions() {
    return {
      allowedItems: STANDALONE_INTERCOM_MESSENGER_ALLOWED_ITEMS.concat([inserterMenuItems.endBot]),
      onSelectItem: (
        { ModelClass, stepCreationParams, customItemHandler }: StepParameters,
        { connectionPoint }: EdgeParameters,
      ) => {
        let insertedItem = this.pathInserterOnSelectItem(
          { ModelClass, stepCreationParams, customItemHandler },
          { connectionPoint },
        );

        this.maybeCreateDefaultPathEndingStep(insertedItem, this.defaultPathEndingStepType);

        return insertedItem;
      },
    };
  }

  get defaultPathEndingStepType() {
    if (this.isZendeskConfigured) {
      return stepTypes.handoffToZendeskAgent;
      // } else if (this.isSalesforceConfigured) {
      //   return stepTypes.handoffToSalesforceAgent;
    } else {
      return stepTypes.handoffWithJavaScript;
    }
  }

  get stepInserterMenuOptions() {
    return {
      allowedItems: STANDALONE_INTERCOM_MESSENGER_ALLOWED_ITEMS,
    };
  }
}
