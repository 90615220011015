/* RESPONSIBLE TEAM: team-workflows */
import {
  NO_TRIGGER_TARGET,
  TRIGGERABLE_BOT_TYPE,
  FROM_ANOTHER_WORKFLOW,
  WORKFLOWS_SETUP_TRIGGER_DURING_CONVERSATION,
  WORKFLOWS_SETUP_TRIGGER_START_CONVERSATION,
  WORKFLOWS_SETUP_TRIGGER_INTERNAL_PROCESS,
  WORKFLOWS_SETUP_TRIGGER_CUSTOMER_FACING,
} from 'embercom/lib/operator/custom-bots/constants';
import {
  matchBehaviors,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import ReusableBotEditorConfig from 'embercom/objects/visual-builder/configuration/editor/reusable-bot';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

import CustomBotConfig, { type ColumnConfigs } from '../custom-bot';
import type { BotConfigTarget } from '../configuration';

export default class NoTriggerCustomBotConfig extends CustomBotConfig {
  get target(): BotConfigTarget {
    return NO_TRIGGER_TARGET;
  }

  get botType() {
    return TRIGGERABLE_BOT_TYPE;
  }

  get objectType() {
    return objectTypes.triggerableCustomBot;
  }

  get trigger() {
    return null;
  }

  get matchBehavior() {
    return matchBehaviors.transient;
  }

  get titleTranslationKey() {
    return 'operator.custom-bot.trigger.no-trigger.title';
  }

  get descriptionTranslationKey() {
    return 'operator.custom-bot.trigger.no-trigger.description';
  }

  get icon(): InterfaceIconName {
    return 'link';
  }

  get columnKeys() {
    let columnKeys: (keyof ColumnConfigs)[] = [
      'title',
      'state',
      'updatedAt',
      'lastUpdatedBy',
      'sentCount',
      'engagedPercent',
      'completedPercent',
      'createdAt',
      'firstWentLiveAt',
    ];

    return columnKeys;
  }

  get EditorConfigClass() {
    return ReusableBotEditorConfig;
  }

  get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  get triggerContextType() {
    return FROM_ANOTHER_WORKFLOW;
  }

  get workflowsSetupTriggerType(): string[] {
    return [
      WORKFLOWS_SETUP_TRIGGER_START_CONVERSATION,
      WORKFLOWS_SETUP_TRIGGER_DURING_CONVERSATION,
      WORKFLOWS_SETUP_TRIGGER_INTERNAL_PROCESS,
      WORKFLOWS_SETUP_TRIGGER_CUSTOMER_FACING,
    ];
  }
}
