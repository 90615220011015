/* RESPONSIBLE TEAM: team-actions */

// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import type MutableArray from '@ember/array/mutable';

import type AttributeDescriptor from 'embercom/models/operator/visual-builder/attribute-descriptor';
import type Step from 'embercom/models/operator/visual-builder/step';
import type Workflow from 'embercom/models/operator/visual-builder/workflow';
import { stepTypes } from 'embercom/objects/visual-builder/configuration-list';
import EditorConfig from 'embercom/objects/visual-builder/configuration/editor';
import type {
  PathConfigGenerationParams,
  StepConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import { AiAgentWorkflowPathConfig } from 'embercom/objects/visual-builder/configuration/path';
import { AiAgentWorkflowAttributeCollectorConfiguration } from 'embercom/objects/visual-builder/configuration/step/attribute-collector';
import type IntlService from 'embercom/services/intl';

export default class AiAgentWorkflowEditorConfig extends EditorConfig {
  @service declare intl: IntlService;

  public targetChannels: MutableArray<string>;

  constructor({
    areStepPaywallsActive,
    workflow,
    targetChannels,
  }: {
    areStepPaywallsActive?: boolean;
    workflow?: Workflow;
    targetChannels: MutableArray<string>;
  }) {
    super({ areStepPaywallsActive, workflow });
    this.targetChannels = targetChannels;
  }

  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new AiAgentWorkflowPathConfig({ path, editorState });
  }

  generateStepConfig<S extends Step>({ step, pathConfig }: StepConfigGenerationParams<S>) {
    let stepConfigArgs = { step, pathConfig };

    switch (step.typeKey) {
      case stepTypes.attributeCollector:
        return new AiAgentWorkflowAttributeCollectorConfiguration(stepConfigArgs);
      default:
        return super.generateStepConfig(stepConfigArgs);
    }
  }

  get showGoalsPanel(): boolean {
    return false;
  }

  @computed('workflow.attributeDescriptors.@each.{type,name,listOptions,archived}')
  get localAttributes(): Array<AttributeDescriptor> {
    return (
      this.workflow?.attributeDescriptors.filter((attribute) => !attribute.archived).toArray() ?? []
    );
  }

  @computed('workflow.attributeDescriptors.@each.{type,name,listOptions,archived}')
  get allLocalAttributes(): Array<AttributeDescriptor> {
    return this.workflow?.attributeDescriptors.toArray() ?? [];
  }
}
