/* RESPONSIBLE TEAM: team-standalone */

import { tracked } from '@glimmer/tracking';
import {
  StandalonePlatformToIcon,
  type StandalonePlatform,
} from 'embercom/objects/standalone/constants';
import { isPresent } from '@ember/utils';
import dataFormatMap from 'embercom/models/data/attributes/data-format-map';

export const REQUIRED_ATTRIBUTES = {
  userFields: ['id', 'name', 'email'],
  ticketFields: ['id', 'subject', 'description'],
  caseFields: ['id', 'subject', 'description', 'origin'],
  organizationFields: [
    {
      platformIdentifier: 'id',
      intercomAttribute: 'remote_company_id',
      intercomFieldType: 'string' as IntercomAttributeType,
    },
    {
      platformIdentifier: 'name',
      intercomAttribute: 'name',
      intercomFieldType: 'string' as IntercomAttributeType,
    },
  ],
};

export type FieldType =
  | 'text'
  | 'textarea'
  | 'checkbox'
  | 'date'
  | 'integer'
  | 'decimal'
  | 'regexp'
  | 'dropdown'
  | 'multiselect'
  | 'lookup'
  | 'tagger'
  | 'id'
  | 'string'
  | 'address'
  | 'email'
  | 'phone'
  | 'boolean'
  | 'datetime'
  | 'url'
  | 'double'
  | 'picklist';

type IntercomAttributeType = 'string' | 'integer' | 'float' | 'boolean' | 'date';

export interface UserFieldResponse {
  id: string;
  name: string;
  description?: string;
  type: FieldType;
  required: boolean;
  intercom_cda?: {
    identifier: string;
  };
}

export interface TicketFieldResponse {
  id: string;
  name: string;
  description?: string;
  type: FieldType;
  required: boolean;
  intercom_cvda?: {
    id: string;
  };
}

export interface OrganizationFieldResponse {
  id: string;
  name: string;
  description?: string;
  type: FieldType;
  required: boolean;
  intercom_cda?: {
    identifier: string;
    type: IntercomAttributeType;
  };
  intercom_sda?: {
    identifier: string;
    type: IntercomAttributeType;
  };
}

export class UserField {
  id: string;
  name: string;
  description?: string;
  type: FieldType;
  required: boolean;
  @tracked isSynchronizing?: boolean = false;
  intercom_cda?: {
    identifier: string;
  };

  constructor(response: UserFieldResponse) {
    this.id = response.id;
    this.name = response.name;
    this.description = response.description;
    this.type = response.type;
    this.required = response.required;
    this.intercom_cda = response.intercom_cda;
  }
}

export class OrganizationField {
  id: string;
  name: string;
  description?: string;
  type: FieldType;
  required: boolean;
  @tracked isSynchronizing?: boolean = false;
  intercom_cda?: {
    identifier: string;
    type: IntercomAttributeType;
  };
  intercom_sda?: {
    identifier: string;
    type: IntercomAttributeType;
  };

  constructor(response: OrganizationFieldResponse) {
    this.id = response.id;
    this.name = response.name;
    this.description = response.description;
    this.type = response.type;
    this.required = response.required;
    this.intercom_cda = response.intercom_cda;
    this.intercom_sda = response.intercom_sda;
  }
}

export class TicketField {
  id: string;
  name: string;
  description?: string;
  type: FieldType;
  required: boolean;
  @tracked isSynchronizing?: boolean = false;
  intercom_cvda?: {
    id: string;
  };

  constructor(response: TicketFieldResponse) {
    this.id = response.id;
    this.name = response.name;
    this.description = response.description;
    this.type = response.type;
    this.required = response.required;
    this.intercom_cvda = response.intercom_cvda;
  }
}

export class StandaloneCompanyAttribute {
  isSyncedWithPlatform: boolean;
  standalonePlatform?: StandalonePlatform;
  organizationField?: OrganizationField;
  icon: string;
  name: string;
  intercomAttribute: any;
  formatNameForRequiredZendeskAttributes?: string;

  constructor({
    standalonePlatform,
    intercomAttribute,
    organizationField,
  }: {
    standalonePlatform?: StandalonePlatform;
    intercomAttribute?: any;
    organizationField?: OrganizationField;
  }) {
    this.isSyncedWithPlatform = isPresent(standalonePlatform);
    this.standalonePlatform = standalonePlatform;
    this.intercomAttribute = intercomAttribute;
    this.organizationField = organizationField;
    this.name = organizationField?.name || intercomAttribute?.name;
    this.icon = isPresent(standalonePlatform)
      ? StandalonePlatformToIcon[standalonePlatform!]
      : intercomAttribute?.icon;
    if (
      organizationField &&
      organizationField.required &&
      organizationField.intercom_sda &&
      organizationField.intercom_sda.type
    ) {
      this.formatNameForRequiredZendeskAttributes =
        dataFormatMap[organizationField.intercom_sda.type as keyof typeof dataFormatMap] ||
        'settings.data-format-map.unknown';
    }
  }
}
