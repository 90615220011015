/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-workflows */
/* eslint-disable no-restricted-imports */
import type MutableArray from '@ember/array/mutable';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';
import { getAttributeService } from 'embercom/lib/container-lookup';
import EditorConfig, {
  type StepConfigGenerationParams,
  type AttributeGroupList,
  type PathConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import { TicketTriggerPathConfig } from 'embercom/objects/visual-builder/configuration/path';
import type Step from 'embercom/models/operator/visual-builder/step';
import type StepConfig from '../step';
import { stepTypes } from '../../configuration-list';
import { TargetContextAwareNoteConfiguration } from '../step/note';
import type Workflow from 'embercom/models/operator/visual-builder/workflow';

// TODO: Refactor this class to extend TargetContextEditorConfig
export default class TicketTriggerEditorConfig extends EditorConfig {
  public readonly isBackgroundOnly = true;
  public readonly canBeTicketTrigger = true;
  public readonly targetChannels: MutableArray<string>;
  public readonly targetContexts: TargetContext[];

  constructor({
    targetChannels,
    areStepPaywallsActive,
    targetContexts,
    workflow,
  }: {
    targetChannels: MutableArray<string>;
    areStepPaywallsActive: boolean;
    targetContexts: TargetContext[] | null;
    workflow?: Workflow;
  }) {
    super({ areStepPaywallsActive, workflow });
    this.targetContexts = targetContexts || [TargetContext.CustomerTicket];
    this.targetChannels = targetChannels;
    this.attributeService = getAttributeService();
  }

  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new TicketTriggerPathConfig({
      path,
      editorState,
      targetContexts: this.targetContexts,
    });
  }

  generateStepConfig<S extends Step>({
    step,
    pathConfig,
  }: StepConfigGenerationParams<S>): StepConfig {
    let stepConfigArgs = { step, pathConfig };
    if (!this.app.canUseWorkflowsTicketsV3) {
      return super.generateStepConfig(stepConfigArgs);
    }

    switch (step.typeKey) {
      case stepTypes.note:
        return new TargetContextAwareNoteConfiguration({
          ...stepConfigArgs,
          attributeService: this.attributeService,
        });
      default:
        return super.generateStepConfig(stepConfigArgs);
    }
  }

  get supportsUserTargeting(): boolean {
    return !this.targetContexts.includes(TargetContext.TrackerTicket);
  }

  get showAudiencePreview(): boolean {
    return false;
  }

  get showGoalsPanel(): boolean {
    return !this.app.canUseWorkflowsTicketsV3;
  }

  get workflowMatchingTargetingAttributes(): AttributeGroupList {
    let groups = [
      ...this.attributeService.attributesToGroupList(
        this.attributeService.visualBotBuilderConditionalAttributes,
      ),
      ...this.attributeService.ticketAttributesByType,
    ];

    // Filter out user attributes if they're not allowed
    if (!this.supportsUserTargeting) {
      groups = this.excludeUserAndCompanyAttributes(groups);
    }

    // Filter out message and conversation attributes if they're not allowed
    if (!this.includeConversationAttributes) {
      groups = this.excludeMessageAndConversationAttributes(groups);
    }

    // Filter out attributes that are not applicable to the target context
    groups = this.filterInapplicableAttributesForTarget(groups);

    // Filter ticket category attribute from trigger settings
    groups = this.filterTicketCategoryAttribute(groups);

    return groups;
  }

  get runtimeMatchingTargetingAttributes(): AttributeGroupList {
    let groups = [...super.runtimeMatchingTargetingAttributes];

    // Filter out user attributes if they're not allowed
    if (!this.supportsUserTargeting) {
      groups = this.excludeUserAndCompanyAttributes(groups);
    }

    // Filter out message and conversation attributes if they're not allowed
    if (!this.includeConversationAttributes) {
      groups = this.excludeMessageAndConversationAttributes(groups);
    }

    // Filter out attributes that are not applicable to the target context
    groups = this.filterInapplicableAttributesForTarget(groups);

    // Filter ticket category attribute from branch if a single target context is selected
    if (this.targetContexts.length === 1) {
      groups = this.filterTicketCategoryAttribute(groups);
    }

    return groups;
  }

  get supportsConversationChannelTargeting(): boolean {
    if (
      this.targetContexts.includes(TargetContext.BackOfficeTicket) ||
      this.targetContexts.includes(TargetContext.TrackerTicket)
    ) {
      return false;
    }

    return true;
  }

  get targetToEntityMapping(): string[] {
    let mapping: Record<TargetContext, string> = {
      [TargetContext.Conversation]: 'conversation',
      [TargetContext.CustomerTicket]: 'customer_ticket',
      [TargetContext.BackOfficeTicket]: 'back_office_ticket',
      [TargetContext.TrackerTicket]: 'tracker_ticket',
    };

    return this.targetContexts.map((context) => mapping[context]);
  }

  private filterInapplicableAttributesForTarget(
    attributes: AttributeGroupList,
  ): AttributeGroupList {
    return attributes.map((originalGroup) => {
      // Clone the group so we don't mutate the original object - this can cause issues when switching between contexts
      let group = Object.assign({}, originalGroup);
      group.attributes = group.attributes.filter((attribute) => {
        if (attribute.entityTypes) {
          return this.targetToEntityMapping.every((context) =>
            attribute.entityTypes.includes(context),
          );
        }
        return true;
      });
      return group;
    });
  }

  private excludeUserAndCompanyAttributes(groups: AttributeGroupList): AttributeGroupList {
    return this.excludeGroupsWithHeadings(groups, ['Person data', 'Company data']);
  }

  private excludeMessageAndConversationAttributes(groups: AttributeGroupList): AttributeGroupList {
    return this.excludeGroupsWithHeadings(groups, ['Conversation data', 'Message data']);
  }

  private excludeGroupsWithHeadings(
    groups: AttributeGroupList,
    headings: string[],
  ): AttributeGroupList {
    return groups.filter((group) => !group.heading || !headings.includes(group.heading));
  }

  private get includeConversationAttributes() {
    return (
      this.targetContexts.length === 1 && this.targetContexts[0] === TargetContext.CustomerTicket
    );
  }

  private filterTicketCategoryAttribute(groups: AttributeGroupList): AttributeGroupList {
    return groups.map((orignalGroup) => {
      // Clone the group so we don't mutate the original
      let group = Object.assign({}, orignalGroup);
      group.attributes = group.attributes.filter((attribute) => {
        return attribute.cda_id !== 'ticket_category';
      });
      return group;
    });
  }

  get targetingSectionTitle(): string {
    if (!this.app.canUseWorkflowsTicketsV3) {
      return this.intl.t('operator.workflows.visual-builder.workflow-trigger-node.audience');
    }

    return this.intl.t('operator.workflows.visual-builder.workflow-trigger-node.filters');
  }

  get targetingAttributePickerLabel(): string {
    if (!this.app.canUseWorkflowsTicketsV3) {
      return this.intl.t(
        'matching-system.audience-selector.audience-rule-editor.add-audience-rule',
      );
    }

    return this.intl.t('operator.workflows.visual-builder.workflow-trigger-node.add-filters');
  }
}
