/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import { type AttributePredicate } from '@intercom/interblocks.ts';
import type IntlService from 'embercom/services/intl';
import { getIntlService, getApp } from 'embercom/lib/container-lookup';
import type EditorConfig from 'embercom/objects/visual-builder/configuration/editor';
import { type TRIGGER_CONTEXT_TYPES } from 'embercom/lib/operator/custom-bots/constants';
import type { BotConfigTarget } from './configuration';
import { type InterfaceIconSet, type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type Workflow from 'embercom/models/operator/visual-builder/workflow';

interface ColumnConfig {
  valuePath: string;
  labelTranslationKey: string;
  minWidth?: string;
}

export class Column {
  valuePath: string;
  label: string;
  minWidth?: string;
  isSortable?: boolean;

  constructor(intlService: any, config: ColumnConfig) {
    this.valuePath = config.valuePath;
    this.label = intlService.t(config.labelTranslationKey);
    this.minWidth = config.minWidth;
  }
}

export class ColumnConfigs {
  title: ColumnConfig = {
    valuePath: 'title',
    labelTranslationKey: 'operator.custom-bot.list-columns.title',
  };
  state: ColumnConfig = {
    valuePath: 'state',
    labelTranslationKey: 'operator.custom-bot.list-columns.state',
  };
  updatedAt: ColumnConfig = {
    valuePath: 'updatedAt',
    labelTranslationKey: 'operator.custom-bot.list-columns.updated-at',
  };
  lastUpdatedBy: ColumnConfig = {
    valuePath: 'lastUpdatedBy',
    labelTranslationKey: 'operator.custom-bot.list-columns.last-updated-by',
  };
  channels: ColumnConfig = {
    valuePath: 'channels',
    labelTranslationKey: 'operator.custom-bot.list-columns.channels',
  };
  sentCount: ColumnConfig = {
    valuePath: 'sentCount',
    labelTranslationKey: 'operator.custom-bot.list-columns.sent',
  };
  goalPercent: ColumnConfig = {
    valuePath: 'goalPercent',
    labelTranslationKey: 'operator.custom-bot.list-columns.goal',
  };
  engagedPercent: ColumnConfig = {
    valuePath: 'engagedPercent',
    labelTranslationKey: 'operator.custom-bot.list-columns.engaged',
  };
  completedPercent: ColumnConfig = {
    valuePath: 'completedPercent',
    labelTranslationKey: 'operator.custom-bot.list-columns.completed',
  };
  audienceTypes: ColumnConfig = {
    valuePath: 'audienceTypes',
    labelTranslationKey: 'operator.custom-bot.list-columns.people-type',
    minWidth: '160px',
  };
  createdAt: ColumnConfig = {
    valuePath: 'createdAt',
    labelTranslationKey: 'operator.custom-bot.list-columns.created-at',
    minWidth: '144px',
  };
  firstWentLiveAt: ColumnConfig = {
    valuePath: 'firstWentLiveAt',
    labelTranslationKey: 'operator.custom-bot.list-columns.first-set-live',
    minWidth: '120px',
  };
}

export interface PredicateOption {
  icon: InterfaceIconName;
  iconSet: InterfaceIconSet;
  value: string;
  translationKey: string;
  tooltipTranslationKey?: string;
  predicate: AttributePredicate;
}

export interface TriggerPredicates {
  type: 'or' | 'and' | 'attribute';
  options: PredicateOption[];
}

export enum TargetContext {
  Conversation,
  CustomerTicket,
  BackOfficeTicket,
  TrackerTicket,
}

export default abstract class CustomBotConfig {
  readonly columnConfigs = new ColumnConfigs();

  constructor(public customBot?: any) {
    // type is `CustomBot` (app/operator/models/custom-bot.js)
    this.customBot = customBot;
  }

  get intl(): IntlService {
    return getIntlService();
  }

  abstract get target(): BotConfigTarget;

  abstract get botType(): string;

  abstract get objectType(): number;

  get trigger(): string | null {
    return null;
  }

  abstract get matchBehavior(): number;

  get rolePredicateGroup(): object {
    return {};
  }

  abstract get titleTranslationKey(): string;

  get title() {
    return this.intl.t(this.titleTranslationKey);
  }

  abstract get descriptionTranslationKey(): string;

  get triggerPredicatesDescriptionTranslationKey(): string | null {
    return null;
  }

  get isBeta() {
    return false;
  }

  // isBeta() takes precedence over isNew if both are true
  get isNew() {
    return false;
  }

  get description() {
    return this.intl.t(this.descriptionTranslationKey);
  }

  abstract get icon(): InterfaceIconName;

  get columnKeys() {
    let columnKeys: (keyof ColumnConfigs)[] = [
      'title',
      'state',
      'updatedAt',
      'lastUpdatedBy',
      'sentCount',
      'goalPercent',
      'engagedPercent',
      'completedPercent',
      'audienceTypes',
      'createdAt',
      'firstWentLiveAt',
    ];

    return columnKeys;
  }

  get columns() {
    return this.columnKeys.map((key) => {
      return new Column(this.intl, this.columnConfigs[key]);
    });
  }

  get editorConfig(): EditorConfig {
    return new this.EditorConfigClass({
      areStepPaywallsActive: this.areStepPaywallsActive,
      workflow: this.workflow,
    });
  }

  abstract get EditorConfigClass(): typeof EditorConfig;

  get triggerPredicates(): TriggerPredicates | null {
    return null;
  }

  visibleToApp(_app: any): boolean {
    return true;
  }

  get selectableCustomBotType(): boolean {
    return true;
  }

  hasRequiredBillingFeature(app: any): boolean {
    return this.requiredBillingFeature === null
      ? true
      : (app.canUseFeature(this.requiredBillingFeature) as boolean);
  }

  get areStepPaywallsActive(): boolean {
    return this.hasRequiredBillingFeature(getApp());
  }

  abstract get requiredBillingFeature(): string | null;

  // This is purely to do with grouping triggers for the template modal UI.
  // We need to rename this to something else to make it clearer
  abstract get triggerContextType(): (typeof TRIGGER_CONTEXT_TYPES)[number];

  get selectedTargetContexts(): TargetContext[] | null {
    return null;
  }

  get workflow(): Workflow | undefined {
    return this.customBot?.visualBuilderObject;
  }
}
