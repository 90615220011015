/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import containerLookup from 'embercom/lib/container-lookup';
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency-decorators';
import { stepModelClasses, stepTypes } from 'embercom/objects/visual-builder/configuration-list';
import { next, schedule } from '@ember/runloop';
import type ChatMessage from 'embercom/models/operator/visual-builder/step/chat-message';
import type Note from 'embercom/models/operator/visual-builder/step/note';
import type Store from '@ember-data/store';
import type Workflow from 'embercom/models/operator/visual-builder/workflow';
import type GraphLayout from 'embercom/objects/workflows/graph-editor/graph-layout';
import { type CreateStepParams } from 'embercom/models/operator/visual-builder/step';
import type Step from 'embercom/models/operator/visual-builder/step';
import type ConnectionPoint from 'embercom/models/operator/visual-builder/connection-point';
import { taskFor } from 'ember-concurrency-ts';
import type Group from 'embercom/models/operator/visual-builder/group';
import type Edge from 'embercom/models/operator/visual-builder/edge';
import type ArrayProxy from '@ember/array/proxy';
import type ConversationSla from 'embercom/models/inbox/conversation-sla';
import type OfficeHoursSchedule from 'embercom/models/office-hours-schedule';
import type ConversationTopic from 'embercom/models/conversational-insights/conversation-topic';
import type WorkflowEditorService from 'embercom/services/workflow-editor-service';
import type LogService from 'embercom/services/log-service';
import { type ComponentLike } from '@glint/template';
import type AttributeDescriptor from 'embercom/models/operator/visual-builder/attribute-descriptor';
import type { SourceObject } from 'embercom/models/operator/visual-builder/attribute-descriptor';
import { Type as AttributeDescriptorType } from 'embercom/models/operator/visual-builder/attribute-descriptor';
import generateUUID from 'embercom/lib/uuid-generator';
import { EntityType } from 'embercom/models/data/entity-types';
import type ActionOutputParameterDescriptor from 'embercom/models/workflow-connector/action-output-parameter-descriptor';

export enum SideSheetKeys {
  AIAgentSettings = 'ai-agent-settings',
  TriggerInfoNode = 'trigger-info-node',
}

export type SideSheetData = {
  headingText?: string;
  header?: ComponentLike;
  body: ComponentLike;
  className?: string;
  key?: SideSheetKeys;
  closeWhileUploading?: boolean;
  onSideSheetClose?: () => void;
};

type FocusableObject = Step | ConnectionPoint | null | undefined;

export type EditorStateArgs = {
  store: Store;
  workflow: Workflow;
  shouldShowValidations: boolean;
  layout: GraphLayout;
  logService?: LogService;
};

type CreateStepArgs = {
  group: Group;
  ModelClass: typeof Step; // This is the actual class, not an instance of a step class
  stepCreationParams?: CreateStepParams;
  insertAtIndex: number;
  analyticsData?: Record<string, unknown>;
  isTemplatePreview?: boolean;
};
type ReplaceStepArgs = Omit<CreateStepArgs, 'insertAtIndex'> & { replaceAtIndex: number };

type EdgeArgs = {
  outwardConnectionPoint: ConnectionPoint;
  toGroup: Group;
};

interface AnalyticsParams {
  skipAnalyticsTracking?: boolean;
  trigger?: string;
}

export default class EditorState {
  @tracked shouldShowValidations: boolean;
  @tracked sideSheetData: SideSheetData | null;
  @tracked conversationSlas: ArrayProxy<ConversationSla> | undefined;
  @tracked officeHoursSchedules: ArrayProxy<OfficeHoursSchedule> | undefined;
  @tracked conversationTopics: ArrayProxy<ConversationTopic> | undefined;
  @tracked activeComposerStep?: ChatMessage | Note;
  @tracked focusableObject: FocusableObject = null;

  store: Store;
  workflow: Workflow;
  layout: GraphLayout;
  intercomEventService: any;
  workflowEditorService: WorkflowEditorService;
  logService?: LogService;
  contentEditorService: $TSFixMe;

  constructor({ store, workflow, shouldShowValidations, layout, logService }: EditorStateArgs) {
    this.store = store;
    this.workflow = workflow;
    this.shouldShowValidations = shouldShowValidations ?? true;
    this.layout = layout;
    this.intercomEventService = containerLookup('service:intercomEventService');
    this.workflowEditorService = containerLookup('service:workflowEditorService');
    this.contentEditorService = containerLookup('service:contentEditorService');
    this.logService = logService;
    this.sideSheetData = null;
    this.workflowEditorService.setState(workflow.id, this);
  }
  get workflowInstanceEntityId() {
    return this.workflow.workflowInstanceEntityId;
  }

  get sideSheetKeys() {
    return SideSheetKeys;
  }

  get isSideSheetOpen() {
    return isPresent(this.sideSheetData);
  }

  get openedSideSheetKey() {
    return this.sideSheetData?.key;
  }

  get canCloseSideSheet() {
    if (this.sideSheetData?.closeWhileUploading) {
      return true;
    }

    return !this.contentEditorService.isUploadingFile;
  }

  @action markAsEdited() {
    this.workflow.hasBeenEdited = true;
  }

  @action openSideSheet({
    analyticsData,
    headingText,
    header,
    body,
    className,
    key,
    closeWhileUploading = false,
    onSideSheetClose,
    isTemplatePreview = false,
  }: { analyticsData?: any; isTemplatePreview?: boolean } & SideSheetData) {
    // In template preview, the side sheet should not be opened
    if (isTemplatePreview) {
      return;
    }
    assert(
      '[EditorState] Either `headingText` or `header` param is required for the side sheet',
      header || headingText,
    );

    this.sideSheetData = {
      headingText,
      body,
      className,
      header,
      key,
      closeWhileUploading,
      onSideSheetClose,
    };

    if (analyticsData) {
      this.intercomEventService.trackAnalyticsEvent({
        ...analyticsData,
        object: analyticsData.overwriteObject ? analyticsData?.object : 'visual_builder_sidesheet',
        action: 'opened',
      });
    }

    this.logInteraction(`Opened side sheet with key=${key}`);
  }

  @action closeSideSheet() {
    if (!this.canCloseSideSheet) {
      return;
    }

    this.sideSheetData?.onSideSheetClose?.();

    let sideSheetKey = this.sideSheetData?.key;
    this.sideSheetData = null;

    this.logInteraction(`Closed side sheet with key=${sideSheetKey}`);
  }

  // focusableObject is set to object param for one render cycle
  @action focusObject(object: FocusableObject = null) {
    let focusableObject: FocusableObject = object;

    if (object instanceof stepModelClasses[stepTypes.replyButtons]) {
      // set focusable object to last reply button
      focusableObject = object.outwardConnectionPoints.lastObject;
    } else if (object instanceof stepModelClasses[stepTypes.answerTerminal]) {
      // skip focus of answer terminal steps
      return;
    }

    this._focusObject(focusableObject);
    this.logInteraction(`Focused object with class_name=${focusableObject?.constructor?.name}`);
  }

  loadDataForEditingActions() {
    taskFor(this._getConversationSlas).perform();
    taskFor(this._getOfficeHoursSchedules).perform();
    taskFor(this._getConversationTopics).perform();
  }

  @task
  *_getConversationSlas() {
    this.conversationSlas = yield this.store.findAll('inbox/conversation-sla');
  }

  @task
  *_getOfficeHoursSchedules() {
    this.officeHoursSchedules = yield this.store.findAll('office-hours-schedule');
  }

  @task
  *_getConversationTopics() {
    this.conversationTopics = yield this.store.findAll(
      'conversational-insights/conversation-topic',
    );
  }

  createStep(stepArgs: CreateStepArgs) {
    let step = this._createStep(stepArgs);

    this.focusObject(step);
    let additionalAnalyticsArgs = stepArgs.analyticsData ?? {};
    this._trackAnalyticsEvent({ object: step, action: 'added', ...additionalAnalyticsArgs });
    return step;
  }

  createEdge(edgeArgs: EdgeArgs) {
    let edge = this._createEdge(edgeArgs);
    this._trackAnalyticsEvent({ object: edge, action: 'added' });
    return edge;
  }

  createAndRenderEdge(edgeArgs: EdgeArgs) {
    let edge = this.createEdge(edgeArgs);
    this.layout.addEdge(edge);
    return edge;
  }

  createEmptyGroupFromConnectionPoint(
    outwardConnectionPoint: ConnectionPoint,
    placeholderName?: string,
  ) {
    let group = this._createGroup();
    group.placeholderName = placeholderName;
    let edge = this._createEdge({ outwardConnectionPoint, toGroup: group });

    // Set layout position for newly created group & insert into the graph editor
    group.layoutColumnIndex = edge.fromGroup.layoutColumnIndex + 1;

    this.layout.connectNewNode(group, edge);

    return group;
  }

  createGroupFromConnectionPoint(args: {
    StepModelClass: typeof Step;
    stepCreationParams?: CreateStepParams;
    outwardConnectionPoint: ConnectionPoint;
    analyticsData?: Record<string, unknown>;
  }) {
    let { StepModelClass, stepCreationParams, outwardConnectionPoint, analyticsData } = args;

    let group = this.createEmptyGroupFromConnectionPoint(outwardConnectionPoint);

    let step = this._createStep({
      group,
      stepCreationParams,
      ModelClass: StepModelClass,
      insertAtIndex: 0,
    });

    this.focusObject(step);

    let additionalAnalyticsArgs = analyticsData ?? {};
    this._trackAnalyticsEvent({
      object: group,
      action: 'added',
      step_type: step.type,
      ...additionalAnalyticsArgs,
    });

    return group;
  }

  createLocalVariable(
    name: string,
    type: AttributeDescriptorType,
    referenceOnly = false,
    sourceObject?: SourceObject,
  ) {
    return this._createLocalVariable(name, type, referenceOnly, sourceObject);
  }

  copyLocalVariable(descriptor: AttributeDescriptor, name: string) {
    return this._copyLocalVariable(descriptor, name);
  }

  copyActionLocalVariable(
    descriptor: ActionOutputParameterDescriptor,
    name: string,
    sourceObject?: SourceObject,
  ) {
    return this._copyLocalVariable(descriptor, name, sourceObject);
  }

  deleteLocalVariable(descriptor: AttributeDescriptor) {
    this._deleteLocalVariable(descriptor);
    this.logInteraction('Local variable deleted');
    this.markAsEdited();
  }

  deleteGroup(group: Group, analyticsParams: AnalyticsParams = { skipAnalyticsTracking: false }) {
    this._trackAnalyticsEvent({ object: group, action: 'removed', ...analyticsParams });
    this._deleteGroup(group);
  }

  deleteEdge(edge: Edge, analyticsParams: AnalyticsParams = { skipAnalyticsTracking: false }) {
    this._trackAnalyticsEvent({ object: edge, action: 'removed', ...analyticsParams });
    this._deleteEdge(edge);
  }

  deleteStep(step: Step) {
    let isLastStep = step.group.steps.length === 1;
    // delete the group if it's the last step and not the starting group
    if (isLastStep && !step.group.isStart) {
      this.logInteraction(
        `Delete step: Deleting group as only one step left with type=${step.type} and num_connection_points=${step.outwardConnectionPoints.length}`,
      );
      this._trackAnalyticsEvent({ object: step.group, action: 'removed', step_type: step.type });
      this._deleteGroup(step.group);
    } else {
      this._trackAnalyticsEvent({ object: step, action: 'removed' });
      this._deleteStep(step);
    }
  }

  replaceStep(stepArgs: ReplaceStepArgs) {
    let { group, ModelClass, stepCreationParams, replaceAtIndex } = stepArgs;
    let step = ModelClass.createNewStep(this.store, stepCreationParams);
    let originalStep = group.steps.objectAt(replaceAtIndex);
    group.steps.replace(replaceAtIndex, 1, [step]);

    this.logInteraction(
      `Replacing step type=${originalStep?.type} at index=${replaceAtIndex} with step_type=${step.type}`,
    );

    this.focusObject(step);
    let additionalAnalyticsArgs = stepArgs.analyticsData ?? {};
    this._trackAnalyticsEvent({ object: step, action: 'replaced', ...additionalAnalyticsArgs });

    // Force an update to connection points. There's a rare case when this might not happen automatically.
    // If the step is replaced with something that is identical in height (such as the same step), we fail to update connection points
    // The path inserter logic to connect the connection point to a new path can crash if this happens.
    let nodeForGroup = this.layout.findNodeForGroup(group);

    if (!nodeForGroup) {
      throw new Error('Group must be present in graph, node for group not found');
    }

    this.layout.updateConnectionPointsForNode(nodeForGroup);

    return step;
  }

  travelToGroup(group: Group) {
    let nodeForGroup = this.layout.graph.nodeForDataObject(group);

    if (!nodeForGroup) {
      throw new Error(`Could not find node for group`);
    }

    this.layout.travelToNode(nodeForGroup);
  }

  logInteraction(message: string) {
    this.logService?.log(`snapshot_id=${this.workflow.id} ${message}`);
  }

  _createEdge(edgeArgs: EdgeArgs) {
    let { outwardConnectionPoint, toGroup } = edgeArgs;
    outwardConnectionPoint.isTerminal = false;
    let edge = this.store.createRecord('operator/visual-builder/edge', {
      outwardConnectionPoint,
      toGroup,
    }) as Edge;

    this.logInteraction('Edge created');
    return edge;
  }

  _createGroup(params?: { isStart?: boolean }) {
    let group = this.store.createRecord('operator/visual-builder/group', { ...params }) as Group;
    group.toggleExpand(true);
    this.workflow.groups.addObject(group);

    // Create a new connection point for the group
    this._createOutwardConnectionPointForGroup(group);

    this.logInteraction('Group created');
    return group;
  }

  _createStep(stepArgs: CreateStepArgs) {
    let { group, ModelClass, stepCreationParams, insertAtIndex } = stepArgs;
    let step = ModelClass.createNewStep(this.store, stepCreationParams);
    group.steps.insertAt(insertAtIndex, step);

    //remove the connection point if the step is a group ending step
    if (group.hasGroupEndingStep && group.outwardConnectionPoint) {
      if (group.outwardConnectionPoint.edge) {
        this.deleteEdge(group.outwardConnectionPoint.edge);
      }
      group.outwardConnectionPoint = null;
    } else if (!group.outwardConnectionPoint && !group.hasGroupEndingStep) {
      // Needed for a new workflow with a brand new group without any connection point
      this._createOutwardConnectionPointForGroup(group);
    }

    this.logInteraction(`Created step model_class=${stepArgs.ModelClass.name} type=${step.type}`);
    return step;
  }

  _createLocalVariable(
    name: string,
    type: AttributeDescriptorType,
    referenceOnly: boolean,
    sourceObject?: SourceObject,
  ) {
    let uuid = generateUUID();
    let identifier = `${this.workflow.workflowInstanceId}_${uuid}`;
    let descriptor = this.store.createRecord('operator/visual-builder/attribute-descriptor', {
      id: identifier,
      name,
      type,
      referenceOnly,
      childrenDescriptors: [],
      sourceObject,
      archived: false,
    }) as AttributeDescriptor;

    this.workflow.attributeDescriptors.addObject(descriptor);

    return descriptor;
  }

  _copyLocalVariable(
    descriptor: AttributeDescriptor | ActionOutputParameterDescriptor,
    name: string,
    sourceObject?: SourceObject,
  ) {
    if (!descriptor) {
      return;
    }
    let newDescriptor = this.createLocalVariable(name, descriptor.type, false, sourceObject);
    let queue: [
      AttributeDescriptor | ActionOutputParameterDescriptor,
      AttributeDescriptor,
      boolean,
    ][] = [[descriptor, newDescriptor, descriptor.type === AttributeDescriptorType.array]];
    while (queue.length > 0) {
      let [original, copy, referenceOnly]: [
        AttributeDescriptor | ActionOutputParameterDescriptor,
        AttributeDescriptor,
        boolean,
      ] = queue.shift()!;
      original.childDescriptors?.forEach((child: any) => {
        let childSourcePath = child.sourceObject?.path ? child.sourceObject.path : child.sourcePath;
        let newChild = this.createLocalVariable(child.name, child.type, referenceOnly, {
          type: EntityType.WorkflowAttributeDescriptor,
          id: copy.id,
          path: childSourcePath,
        });
        copy._childDescriptors.pushObject(newChild);
        queue.push([
          child,
          newChild,
          referenceOnly || child.type === AttributeDescriptorType.array,
        ]);
      });
    }
    return newDescriptor;
  }

  _deleteLocalVariable(descriptor: AttributeDescriptor) {
    if (!descriptor) {
      return;
    }
    descriptor.childDescriptors.toArray().forEach((child) => {
      this._deleteLocalVariable(child);
    });
    descriptor.set('archived', true);
  }

  _deleteEdge(edge: Edge) {
    let outwardConnectionPoint = edge.outwardConnectionPoint;

    if (outwardConnectionPoint?.isGroupLevelConnectionPoint) {
      outwardConnectionPoint.isTerminal = true;
    }

    if (!edge.isDeleted) {
      edge.deleteRecord();
    }

    this.logInteraction('Edge deleted');
    this.layout.removeEdge(edge);
  }

  _deleteGroup(group: Group) {
    this.workflow.groups.removeObject(group);
    this.layout.removeNode(group);
    this.logInteraction('Group deleted');
    this.markAsEdited();
  }

  _deleteStep(step: Step) {
    let currentGroup = step.group;
    step.group.steps.removeObject(step);

    if (!currentGroup.hasGroupEndingStep && !currentGroup.outwardConnectionPoint) {
      // restores path connection point after deleting group ending step
      currentGroup.outwardConnectionPoint =
        this._createOutwardConnectionPointForGroup(currentGroup);
    }

    this.logInteraction(
      `Step deleted with type=${step.type} and num_connection_points=${step.outwardConnectionPoints.length}`,
    );
    this.markAsEdited();
  }

  // focusableObject is set to object param for one render cycle
  _focusObject(object: FocusableObject) {
    if (object === null || object === undefined) {
      this.focusableObject = null;
      return;
    }

    this.focusableObject = object;

    next(this, () =>
      schedule('afterRender', this, () => {
        this.focusableObject = null;
      }),
    );
  }

  _trackAnalyticsEvent({
    object,
    action,
    ...params
  }: {
    object: object;
    action: string;
    [key: string]: any;
  }) {
    if (params?.skipAnalyticsTracking) {
      return;
    }
    this.intercomEventService.trackAnalyticsEvent({ object, action, ...params });
  }

  _createOutwardConnectionPointForGroup(group: Group) {
    let outwardConnectionPoint = this.store.createRecord(
      'operator/visual-builder/connection-point',
      { group, isTerminal: true },
    );

    this.logInteraction('Conection point for group created');
    return outwardConnectionPoint;
  }
}
