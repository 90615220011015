/* RESPONSIBLE TEAM: team-help-desk-experience */

import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';

interface AutoSummarizeLimitReachedWireFormat {
  renderable_type: RenderableType;
  reason: string;
}

export default class AutoSummarizeLimitReached implements RenderableData {
  readonly renderableType = RenderableType.AutoSummarizeLimitReached;
  readonly reason: string;

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  constructor(reason: string) {
    this.reason = reason;
  }

  static deserialize(json: AutoSummarizeLimitReachedWireFormat): AutoSummarizeLimitReached {
    return new AutoSummarizeLimitReached(json.reason);
  }
}
